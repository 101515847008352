
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const ticketmanageService = {
    gettickets,
    getticket,
    ticketseen,
    ticketReOpen,
    ticketClose,
    ticketDelete,
    download_file,
    getunreadticket,
    getcompanylist
    
};

function gettickets(page,close,allshow,company_id){
  if(company_id==undefined){
    company_id="";
  }
  var newticket = '';
  var showall = '';
  if(close == 2){
     close = 0;
     newticket = '&unread=1';
  }
  if(allshow == 1){
    showall = '&all=1';
 }
  return axios({
      method: "get",
      url: `${apiUrl}/get-tickets?page=${page}&closed=${close}${newticket}${showall}&company_id=${company_id}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getticket(ticketnumber){
  return axios({
      method: "get",
      url: `${apiUrl}/get-ticket?ticketnumber=${ticketnumber}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function ticketseen(ticketnumber){
  return axios({
      method: "get",
      url: `${apiUrl}/ticket-seen?ticketnumber=${ticketnumber}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function ticketReOpen(ticketnumber){
  return axios({
      method: "get",
      url: `${apiUrl}/ticket-re-open?ticketnumber=${ticketnumber}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}
function ticketClose(ticketnumber){
  return axios({
      method: "get",
      url: `${apiUrl}/ticket-close?ticketnumber=${ticketnumber}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}
function ticketDelete(ticketnumber){
  return axios({
      method: "get",
      url: `${apiUrl}/ticket-delete?ticketnumber=${ticketnumber}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function download_file(path,name,extension){

  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/download_file?path=${path}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `${name}.${extension}`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });    
}

function getunreadticket(){
  return axios({
      method: "get",
      url: `${apiUrl}/ticket/ticket_unreadcount`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}
function getcompanylist(){
  return axios({
      method: "get",
      url: `${apiUrl}/getcompany`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}
