import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect,useParams} from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Provider } from "react-redux";
import { createStore } from "redux";
import rootreducer from "./reducers";
const axios = require("axios");
const store = createStore(rootreducer);

axios.interceptors.response.use(res => {
  return res;
},err => {
  if (err.response.status === 401) {
      localStorage.clear();
      if(localStorage.length <=0){
          window.location.href = '/auth';
      }
  }
   throw err; 
});

function Blog(){
  let { companyname } = useParams();
  console.warn(companyname);
  return companyname
}

const queryString = window.location.search;
var companyname="";
var data= queryString.split("?");
const results = data.filter(element => {
  return element !== '';
});


function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  // Directly return the joined string
  return splitStr.join(' '); 
}


if(results.length>0){
  companyname= titleCase(results[0]);
 ;
}else{
  companyname="";
}

ReactDOM.render(
  <Provider store={store}> 
      <BrowserRouter>
      <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />

        <Switch>
          
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          {
              !localStorage.getItem('authtoken') ? 
              <>
                <Route path="/auth" render={(props) => <AuthLayout {...props} />} /> 
                {/* <Route path='/auth/login' element={<Blog />}></Route> */}
                <Redirect from="/" to={`/auth/login/${companyname}`}  element={<Blog />} />
                {/* <Redirect from="/" to="/auth/login/"  element={<Blog />} /> */}
                <Route path="/auth/userreset" render={(props) => <AuthLayout {...props} />} >
                  <Redirect to={`/auth/userreset`} />
                </Route> 
              </> : <>
                <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                <Redirect to="/admin"/>
              </>
          }
        <Redirect from="/" to="/auth/login" />
        </Switch>
      </BrowserRouter>
   </Provider> ,
  document.getElementById("root")
);
