import { apiUrl } from "../constant";
import { authtoken } from "global.js";
const axios = require("axios");
export const TemplatenotificationService = {
  getstaff,
  sendmail,
  getdata,
  sendmaildata,
  updatestatus,
};

function getUploadedFiles(start_date, end_date) {
  if (start_date != null && end_date != null) {
    var sendData = { startDate: start_date, endDate: end_date };
  } else {
    var sendData = {};
  }
  return axios({
    method: "post",
    url: `${apiUrl}/client_upload/getUploadedFiles`,
    headers: { Authorization: `Bearer ${authtoken}` },
    data: sendData,
  })
    .then(
      function (response) {
        if (response.status == 200) {
          return response;
        }
      },
      (error) => {
        return error.response;
      }
    )
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getemaillogs_body(id) {
  if (id != null) {
    var sendData = { id: id };
  } else {
    var sendData = {};
  }
  return axios({
    method: "post",
    url: `${apiUrl}/emaillog/get-emaillogs-body`,
    headers: { Authorization: `Bearer ${authtoken}` },
    data: sendData,
  })
    .then(
      function (response) {
        if (response.status == 200) {
          return response;
        }
      },
      (error) => {
        return error.response;
      }
    )
    .catch(function (error) {});
}

function getstaff(page_name) {
  if (page_name != null) {
    var sendData = { page_name: page_name };
  } else {
    var sendData = {};
  }
  return axios({
    method: "post",
    url: `${apiUrl}/templatenotification/getclient`,
    headers: { Authorization: `Bearer ${authtoken}` },
    data: sendData,
  })
    .then(
      function (response) {
        if (response.status == 200) {
          return response;
        }
      },
      (error) => {
        return error.response;
      }
    )
    .catch(function (error) {});
}

function getRecentTrans(url = "") {
  return axios({
    method: "post",
    url: `${apiUrl}/xero/getStatisticsFromXero`,
    headers: { Authorization: `Bearer ${authtoken}` },
    data: { url_to_hit: url },
  })
    .then(
      function (response) {
        if (response.status == 200) {
          return response;
        }
      },
      (error) => {
        return error.response;
      }
    )
    .catch(function (error) {});
}

function sendmail(page_name, usercheck, yearselected, vat_month_name = "") {
  if (page_name != null) {
    var sendData = {
      usercheck: usercheck,
      yearselected: yearselected,
      vat_month_name: vat_month_name,
    };
  } else {
    var sendData = {};
  }
  return axios({
    method: "post",
    url: `${apiUrl}/email/${page_name}`,
    headers: { Authorization: `Bearer ${authtoken}` },
    data: sendData,
  })
    .then(
      function (response) {
        if (response.status == 200) {
          return response;
        }
      },
      (error) => {
        return error.response;
      }
    )
    .catch(function (error) {
      return error;
    });
}
function updatestatus(id,status,columnname){
  var sendData = {
    id: id,
    status: status,
    columnname: columnname,
  };
  return axios({
    method: "post",
    url: `${apiUrl}/email/Updatecompanystatus`,
    headers: { Authorization: `Bearer ${authtoken}` },
    data: sendData,
  })
    .then(
      function (response) {
        if (response.status == 200) {
          return response;
        }
      },
      (error) => {
        return error.response;
      }
    )
    .catch(function (error) {
      return error;
    });
}

function getdata(page_name) {
  return axios({
    method: "get",
    url: `${apiUrl}/${page_name}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(
      function (response) {
        if (response.status == 200) {
          return response;
        }
      },
      (error) => {
        return error.response;
      }
    )
    .catch(function (error) {});
}

function sendmaildata(
  page_name,
  Email_Subject,
  Description,
  Email_From,
  Email_To,
  company_name
) {
  if (page_name != null) {
    var sendData = {
      Email_Subject: Email_Subject,
      Description: Description,
      Email_From: Email_From,
      Email_To: Email_To,
      company_name: company_name,
    };
  } else {
    var sendData = {};
  }
  return axios({
    method: "post",
    url: `${apiUrl}/email/${page_name}`,
    headers: { Authorization: `Bearer ${authtoken}` },
    data: sendData,
  })
    .then(
      function (response) {
        if (response.status == 200) {
          return response;
        }
      },
      (error) => {
        return error.response;
      }
    )
    .catch(function (error) {
      return error;
    });
}
