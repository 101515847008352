import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  InputGroup,
  FormControl,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
// import { Tem } from "../../services/emaillogsservice";
import { TemplatenotificationService } from "../../services/templatenotificationService";
import { Getyearmonth } from "../../services/getyearmonth";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./template.css";
import Popupmodel from "./Popupmodel";


function VatNotification() {
  const [resultdata, setResultdata] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [modal, setModal] = useState(false);
  const [confirmtitle, setConfirmtitle] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [monthselected, setMonthselected] = useState("");
  const [yearselected, setYearselected] = useState("");
  const [loading,setLoading]=useState(false);
  const [disabledbtn,setDisabledbtn]=useState("");
  const [userid,setUserid]=useState("");
  const [vatMonth,setVatMonth]=useState("");
  const [status,setStatus]=useState(false);

  
  //const [years,setYears]=useState(["Select Year","2022","2023","2024","2025"]);

  function get_staff() {
    TemplatenotificationService.getstaff("vat_notification").then(
      (response) => {
        if (response.status == 200) {
          const result = response.data.data;
          console.log(result);
          //setResultdata(result.emaillogs);
          const filteredItems = result.comapnylist.filter(
            (item) =>
              item.name &&
              item.name.toLowerCase().includes(filterText.toLowerCase())
          );
          setResultdata(filteredItems);
        }
      }
    );
  }
  const columns = [
    // {
    //   name: "sno",
    //   selector: (row, index) => index + 1,
    //   maxWidth: "20px",
    // },
    {
      name: "Company Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
    },
    {
        name: "VAT Type",
        selector: (row) => row.conditional,
        sortable: true,
        wrap: true,
      },

    {
      name: "Year",
      selector: (row,index) => (
        <select
          className="form-control custom-select"
          onChange={(e) => getselectedyear(e)}
        >
          {Getyearmonth.getyear().map((data, index) => (
            <option value={data == "Select Year" ? "" : data} key={index+row.user_id} id={index+row.user_id}>
              {data}
            </option>
          ))}
        </select>
      ),
      wrap: true,
    },
    {
      name: "MONTH",
      selector: (row) => (
        <select
          className="form-control custom-select"
          onChange={(e) => getselectedmonth(e)}
        >
          {Getyearmonth.getmonth().map(
            (data, index) => (
              <option
                value={
                  data == "Select Month"
                    ? ""
                    : data + "|?|" + row.user_id + "|?|" + index + "|?|" + row.conditional
                }
                key={index + 1}
              >
                {data}
              </option>
            )
            //<option  value={data=='Select Month' ? "" :data} key={index +1}>{data}</option>
          )}
        </select>
      ),
      wrap: true,
    },
    {
      name: "SENT DETAILS",
      selector: (row) => row.monthyear,
      sortable: true,
      wrap: true,
    },
    {
      name: "ACTION",
      selector: (row,index) => (
        <Button
        // id={`dynamic_${row.user_id + index+1}_1`}
        className="loading-btn1"
        key={row.id+index}
        data={row.user_id+index+1}
        id={row.user_id+index+1}
          color="primary"
           onClick={(e)=>handleSubmit2(e,row.user_id+index+1)}>
             Send Mail
          </Button>

        //  <><Button
        // id={`dynamic_${row.user_id + index+1}_1`}
        // className="loading-btn1"
        // key={row.id+index}
        //   color="primary"
        //    onClick={(e)=>handleSubmit2(e,row.user_id+index+1)}>
        //     Send Mail
        //   </Button>
        //   <Button id={`dynamic_${row.user_id + index+1}`}
        //     color="primary"
        //     disabled
        //     className="loading-btn"
        //     key={row.id+index}
        //   >
        //     <Spinner size="sm">
        //       Loading...
        //     </Spinner>
        //     <span>
        //       {' '}Loading
        //     </span>
        //   </Button>
        // </>
      ),
      wrap: true,
    },
  ];
  useEffect(() => {
    get_staff();
  }, [filterText,status]);

  const filter = (e) => {
    setFilterText(e);
  };



  const getselectedmonth = (e) => {
    setMonthselected(e.target.value);
  };
  const getselectedyear = (e) => {
    setYearselected(e.target.value);
  };
  const toggle = () => {
    let body= "Do you want to send email to this company " + localStorage.getItem("companyname") 
        +"(" + localStorage.getItem("vat_month_name") +  " Vat Report)";

    setConfirmtitle(body);
    setModal(!modal);
  };



const handleSubmit2 = (e,user_id) =>{
   
//     var id=e.target.getAttribute("data");
//     alert(id);
//     document.getElementById(id).innerHTML = '<i className="fas fa-spinner fa-spin"></i> Send Mail';

//   console.log(document.getElementById(id).innerHTML);return;

// wrapper.innerHTML="<i className='fas fa-spinner fa-spin'></i>";

    e.preventDefault();
    setUserid(user_id);
    if (monthselected == "") {
        toast.error("Error", "Please select month");
    } else if (yearselected == "") {
        toast.error("Error", "Please select year");
    } else {
      let data = monthselected.split("|?|");
      let id = data[1];
      let selectedmonth = data[0];
      let vat_type = data[3];
      let month_name;
      let last_year=parseInt(yearselected) - 1;
      if (vat_type == "Quarterly") {
        switch (selectedmonth) {
          case "January":
            month_name = "November "+ last_year + " to January "+ yearselected;
            break;
          case "February":
            month_name = "December "+ last_year +" to February "+ yearselected;
            break;
          case "March":
            month_name = "January "+yearselected +" to March "+  yearselected;
            break;
          case "April":
            month_name = "February "+yearselected +" to April "+ yearselected ;
            break;
          case "May":
            month_name = "March "+yearselected +" to May "+ yearselected;
            break;
          case "June":
            month_name = "April "+yearselected+" to June "+ yearselected;
            break;
          case "July":
            month_name = "May "+yearselected+ " to July "+ yearselected;
            break;
          case "August":
            month_name = "June "+yearselected+" to August "+ yearselected;
            break;
          case "September":
            month_name = "July "+yearselected+ " to September "+ yearselected;
            break;
          case "October":
            month_name = "August " +yearselected+" to October "+ yearselected;
            break;
          case "November":
            month_name = "September "+yearselected+" to November "+yearselected;
            break;
          case "December":
            month_name = "October "+yearselected+" to December "+yearselected;
            break;
        }
       
      }else if(vat_type == "Monthly"){
        switch (selectedmonth) {
          case "January":
            month_name = "January "+yearselected;
            break;
            case "February":
            month_name = "February "+yearselected;
            break;
          case "March":
            month_name = "March "+yearselected;
            break;
          case "April":
            month_name = "April "+yearselected;
            break;
          case "May":
            month_name = "May "+yearselected;
            break;
          case "June":
            month_name = "June "+yearselected;
            break;
          case "July":
            month_name = "July "+yearselected;
            break;
          case "August":
            month_name = "August "+yearselected;
            break;
          case "September":
            month_name = "September "+yearselected;
            break;
          case "October":
            month_name = "October "+yearselected;
            break;
          case "November":
            month_name = "November "+yearselected;
            break;
          default:
            month_name = "December "+yearselected;
        }
      }
      setVatMonth(month_name);
      resultdata.filter((e1) => {
        if (e1.id == id) {
          localStorage.setItem("companyname", e1.name);
          localStorage.setItem("selectedmonth", selectedmonth);
          localStorage.setItem("selectedyear", yearselected);
          localStorage.setItem("vat_month_name", month_name);
        }
      });
      toggle();
      localStorage.setItem("companyname", "");
      localStorage.setItem("selectedmonth", "");
      localStorage.setItem("selectedyear", "");
      localStorage.setItem("vat_month_name","");
    }
  }
  const sendmail= (e) => {
    toggle();
    setLoading(true);
    e.preventDefault();
        TemplatenotificationService.sendmail("vatnotification",monthselected,yearselected,vatMonth).
    then((response) => {
        console.log(response);
        if(response.status == 200) {
          return response.data;
        }

      }).then((data)=>{
        setStatus(true);
        setLoading(false);
        if(data.status=='success'){
          toast.success(data.data.msg);
        }else{
          toast.error(data.data.msg);
        }
        setStatus(false);
    }).catch((error)=>{
        setLoading(false);
      console.log(error);
      toast.error(error);
    })

  }

  return (
    
    <>

      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className="mt--7" fluid>

        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">

            <Card className="shadow">
              <CardHeader>
                <div className="justify-content-end row">
                  <Col xl="6" className="align-items-center">
                    <h2 className="header-title">Vat Notification</h2>
                  </Col>
                  <Col xl="6" className="align-items-center">
                    <InputGroup>
                      <Input
                        onChange={(e) => filter(e.target.value)}
                        filterText={filterText}
                        placeholder="enter your search term ..."
                      />
                      <Button color="primary">Search</Button>
                    </InputGroup>
                  </Col>
                </div>
              </CardHeader>
              <CardBody>
              {loading ? <div className="loader"></div> : 
                <DataTable
                  columns={columns}
                  data={resultdata}
                  pagination
                  defaultSortField="subject"
                />
  }
               

              
                <CardFooter className="py-4"></CardFooter>
             
              </CardBody>
             
            </Card>
          </Col>
        </Row>
{/* start popup model*/}
<Popupmodel modal={modal} data={confirmtitle} toggle={toggle} functionname={sendmail}></Popupmodel>



      </Container>


    </>
  );
}

export default VatNotification;
