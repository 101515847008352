import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  InputGroup,
  FormControl,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
// import { Tem } from "../../services/emaillogsservice";
import { TemplatenotificationService } from "../../services/templatenotificationService";
import { Getyearmonth } from "../../services/getyearmonth";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./template.css";
import Popupmodel from "./Popupmodel";


function VatNotification() {
  const [resultdata, setResultdata] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [modal, setModal] = useState(false);
  const [confirmtitle, setConfirmtitle] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [monthselected, setMonthselected] = useState("");
  const [yearselected, setYearselected] = useState("");
  const [loading,setLoading]=useState(false);
  const [disabledbtn,setDisabledbtn]=useState("");
  const [userid,setUserid]=useState("");
  const [vatMonth,setVatMonth]=useState("");
  const [status,setStatus]=useState(false);

  
  //const [years,setYears]=useState(["Select Year","2022","2023","2024","2025"]);

const getmonthname= (number) =>{
  let month_name;
  switch (number) {
    case "1":
      month_name = "January";
      break;
    case "2":
      month_name = "February";
      break;
    case "3":
      month_name = "March";
      break;
    case "4":
      month_name = "April";
      break;
    case "5":
      month_name = "May";
      break;
    case "6":
      month_name = "June";
      break;
    case "7":
      month_name = "July";
      break;
    case "8":
      month_name = "August";
      break;
    case "9":
      month_name = "September";
      break;
    case "10":
      month_name = "October";
      break;
    case "11":
      month_name = "November";
      break;
    case "12":
      month_name = "December";
      break;
  }
  return month_name;
}

  function get_staff() {
    TemplatenotificationService.getstaff("FinalisationofYearend").then(
      (response) => {
        if (response.status == 200) {
          const result = response.data.data;
          console.log(result);
          //setResultdata(result.emaillogs);
          const filteredItems = result.comapnylist.filter(
            (item) =>
              item.name &&
              item.name.toLowerCase().includes(filterText.toLowerCase())
          );
          setResultdata(filteredItems);
        }
      }
    );
  }
  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
    },
    {
        name: "Financial Year End",
        selector: (row) => getmonthname(row.conditional),
        sortable: true,
        wrap: true,
      },

    {
      name: "Year",
      selector: (row,index) => (
        <select
          className="form-control custom-select"
          onChange={(e) => getselectedyear(e)}
        >
          {Getyearmonth.getyear().map((data, index) => (
            <option value={data == "Select Year" ? "" : data} key={index+row.user_id} id={index+row.user_id}>
              {data}
            </option>
          ))}
        </select>
      ),
      wrap: true,
    },
    {
      name: "MONTH",
      selector: (row) => (
        <select
          className="form-control custom-select"
          onChange={(e) => getselectedmonth(e)}
        >
          {Getyearmonth.getmonth().map(
            (data, index) => (
              <option
                value={
                  data == "Select Month"
                    ? ""
                    : data + "|?|" + row.user_id + "|?|" + index
                }
                key={index + 1}
              >
                {data}
              </option>
            )
            //<option  value={data=='Select Month' ? "" :data} key={index +1}>{data}</option>
          )}
        </select>
      ),
      wrap: true,
    },
    {
      name: "SENT DETAILS",
      selector: (row) => row.monthyear,
      sortable: true,
      wrap: true,
    },
    {
      name: "ACTION",
      selector: (row,index) => (
        <Button
        // id={`dynamic_${row.user_id + index+1}_1`}
        className="loading-btn1"
        key={row.id+index}
        data={row.user_id+index+1}
        id={row.user_id+index+1}
          color="primary"
           onClick={(e)=>handleSubmit2(e,row.user_id+index+1)}>
             Send Mail
          </Button>

        //  <><Button
        // id={`dynamic_${row.user_id + index+1}_1`}
        // className="loading-btn1"
        // key={row.id+index}
        //   color="primary"
        //    onClick={(e)=>handleSubmit2(e,row.user_id+index+1)}>
        //     Send Mail
        //   </Button>
        //   <Button id={`dynamic_${row.user_id + index+1}`}
        //     color="primary"
        //     disabled
        //     className="loading-btn"
        //     key={row.id+index}
        //   >
        //     <Spinner size="sm">
        //       Loading...
        //     </Spinner>
        //     <span>
        //       {' '}Loading
        //     </span>
        //   </Button>
        // </>
      ),
      wrap: true,
    },
  ];
  useEffect(() => {
    get_staff();
  }, [filterText,status]);

  const filter = (e) => {
    setFilterText(e);
  };



  const getselectedmonth = (e) => {
    setMonthselected(e.target.value);
  };
  const getselectedyear = (e) => {
    setYearselected(e.target.value);
  };
  const toggle = () => {
    let body= "Do you want to send email to this company " +
         localStorage.getItem("companyname") +
        "(" +
         localStorage.getItem("selectedmonth") +
         " " +
         localStorage.getItem("selectedyear") +
         " Finalisation of Year End Report)";

    setConfirmtitle(body);
    setModal(!modal);
  };



const handleSubmit2 = (e,user_id) =>{
  e.preventDefault();
  if (monthselected == "") {
    toast.error("Error", "Please select month");
  } else if (yearselected == "") {
    toast.error("Error", "Please select year");
  } else {
    let data = monthselected.split("|?|");
    let id = data[1];
    let selectedmonth = data[0];
    resultdata.filter((e1) => {
      if (e1.id == id) {
        localStorage.setItem("companyname", e1.name);
        localStorage.setItem("selectedmonth", selectedmonth);
        localStorage.setItem("selectedyear", yearselected);
      }
    });
    toggle();
    localStorage.setItem("companyname", "");
    localStorage.setItem("selectedmonth", "");
    localStorage.setItem("selectedyear", "");
  }
  }
  const sendmail= (e) => {
    toggle();
    setLoading(true);
    e.preventDefault();
        TemplatenotificationService.sendmail("FinalisationofYearend",monthselected,yearselected).
    then((response) => {
        console.log(response);
        if(response.status == 200) {
          return response.data;
        }
      }).then((data)=>{
        setStatus(true);
        setLoading(false);
        if(data.status=='success'){
          toast.success(data.data.msg);
        }else{
          toast.error(data.data.msg);
        }
        setStatus(false);
    }).catch((error)=>{
        setLoading(false);
      console.log(error);
      toast.error(error);
    })

  }

  return (
    
    <>

      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className="mt--7" fluid>

        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">

            <Card className="shadow">
              <CardHeader>
                <div className="justify-content-end row">
                  <Col xl="6" className="align-items-center">
                    <h2 className="header-title">Year End Notification</h2>
                  </Col>
                  <Col xl="6" className="align-items-center">
                    <InputGroup>
                      <Input
                        onChange={(e) => filter(e.target.value)}
                        filterText={filterText}
                        placeholder="enter your search term ..."
                      />
                      <Button color="primary">Search</Button>
                    </InputGroup>
                  </Col>
                </div>
              </CardHeader>
              <CardBody>
              {loading ? <div className="loader"></div> : 
                <DataTable
                  columns={columns}
                  data={resultdata}
                  pagination
                  defaultSortField="subject"
                />
  }
               

              
                <CardFooter className="py-4"></CardFooter>
             
              </CardBody>
             
            </Card>
          </Col>
        </Row>
{/* start popup model*/}
<Popupmodel modal={modal} data={confirmtitle} toggle={toggle} functionname={sendmail}></Popupmodel>



      </Container>


    </>
  );
}

export default VatNotification;
