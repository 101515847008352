import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const Emaillogsservice = {
  emaillogs,
  getemaillogs_body,
  getuploadinvoicefile,
};

function getUploadedFiles(start_date,end_date){
  if(start_date!=null && end_date!=null){
    var sendData = { startDate : start_date, endDate : end_date};
  }else{
    var sendData = {};
  }
    return axios({
        method: "post",
        url: `${apiUrl}/client_upload/getUploadedFiles`,
        headers: {Authorization: `Bearer ${authtoken}` },
        data : sendData
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response; 
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function getemaillogs_body(id){
  if(id!=null){
    var sendData = { id : id};
  }else{
    var sendData = {};
  }  
  return  axios({
    method: "post",
    url: `${apiUrl}/emaillog/get-emaillogs-body`,
    headers: {Authorization: `Bearer ${authtoken}` },
    data : sendData,
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response; 
    })
    .catch(function (error) {
    });        
}

function emaillogs(page, size,filter){
  if(filter==undefined){
    filter="";
  }
  return  axios({
      method: "get",
      url: `${apiUrl}/emaillog/get-emaillogs?page=${page}&per_page=${size}&search=${filter}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getRecentTrans(url=''){
  return axios({
      method: "post",
      url: `${apiUrl}/xero/getStatisticsFromXero`,
      headers: {Authorization: `Bearer ${authtoken}` },
      data : {'url_to_hit' : url }
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getuploadinvoicefile(page, size,filter){
  if(filter==undefined){
    filter="";
  }
  return  axios({
      method: "get",
      url: `${apiUrl}/getuploadfile?page=${page}&per_page=${size}&search=${filter}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

