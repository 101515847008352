import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const activitymanageService = {
    getUploadedFiles,
    getDashboardStatistics,
    getRecentTrans,
    getstatus
};

function getUploadedFiles(start_date,end_date){
  if(start_date!=null && end_date!=null){
    var sendData = { startDate : start_date, endDate : end_date};
  }else{
    var sendData = {};
  }
    return axios({
        method: "post",
        url: `${apiUrl}/client_upload/getUploadedFiles`,
        headers: {Authorization: `Bearer ${authtoken}` },
        data : sendData
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response; 
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function getDashboardStatistics(){
  return axios({
      method: "get",
      url: `${apiUrl}/xero/getDashboardFromXero`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getRecentTrans(url=''){
  return axios({
      method: "post",
      url: `${apiUrl}/xero/getStatisticsFromXero`,
      headers: {Authorization: `Bearer ${authtoken}` },
      data : {'url_to_hit' : url }
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/statuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
