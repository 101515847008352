import { useState,useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import {
  useHistory
} from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  TabContent,
  TabPane,
  Modal,
  ModalBody
} from "reactstrap";
import ReactQuill from 'react-quill';
const axios = require("axios");
import { authtoken } from "global.js";
import { apiUrl } from "../../constant";
import { toast } from "react-toastify";
// core components
import { staffmanageService } from "../../services/staffmanageService.js";

const Staff = (props) => {
  const history = useHistory();
  const [staffs, setStaffs] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [createstaffModalOpen, setcreateStaffModalOpen] = useState(false);
  const [value, setValue] = useState('');
 const get_staff_data = async (pageNumber = 1,company='') => {
    staffmanageService.getstaffs(pageNumber,company).then((response) => {
      setStaffs(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setLastPageData(response.data.data.last_page);
    });
  }


   const handlePageClick = (e, index) => {
    e.preventDefault();
    get_staff_data(index);
  };

  const handlePreviousClick = () => {
    get_staff_data(prev);
  };

  const handleNextClick = () => {
    get_staff_data(next);
  };

  useEffect(() => {
    get_staff_data()
  }, []);

  // function staff_details(id){
  //    history.push({
  //      pathname: "get_staff/"+id,
  //    });

  // }

const initialStateData = {
  name : "",
  email : "",
  password : "",
  level : 1
};

const [inputFields, setInputfields] = useState(initialStateData);
function onRespondClick(){
    const formData = new FormData();
    formData.append('name',inputFields.name);
    formData.append('email',inputFields.email);
    formData.append('password',inputFields.password);
    formData.append('level',inputFields.level);
    axios({
      method: "post",
      url: `${apiUrl}/staff/create`,
      data: formData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(
        function (response) {
          console.log(response)
          if (response.status == 200) {
            setInputfields(initialStateData)
            setcreateStaffModalOpen(false)
            toast.success(response.data.message);
            get_staff_data()
          }else{
            toast.error(response.data.message);
          }
        },
        (error) => {
          return error.response;
        }
      )
      .catch(function (error) {});
  }
  function inputhandler(e){
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }
  const user_info = JSON.parse(localStorage.getItem("user-info"));

  const deleterow = (e) => {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this user data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((deletdata) => {
    if(deletdata){
          staffmanageService.deleteuser(e).then((response) => {
            if(response.status == 200){
              const newList = staffs.filter((item) => item.id !== e);
              setStaffs(newList);
              toast.success(response.data.message);
            }else{
              toast.error(response.data.message);
            }
          });
    }
      });
      
  };
  const handleclick = (e) => {
     history.push({
      pathname: 'edituser/'+e,
    });
  }
  const searchcompany = (e) => {
    if(e.target.value != ''){
         if(e.key === 'Enter'){
           e.preventDefault();
           var company_name = e.target.value;
           get_staff_data(1,company_name)
       }
    }   
   }
  return (
    <>
       <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      
      <Container className="mt--7" fluid>

        <Row>
       
        <Col className="mb-5 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader>
          <div className="align-items-center row">
            <div className="col-8"><Form className="navbar-search  form-inline mr-3 d-none d-md-flex ml-lg-auto">
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative" id="search">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search" type="text" onKeyDown={searchcompany} />
                  </InputGroup>
                </FormGroup>
              </Form></div>
              
              <div className="text-right col-4"> 
              {
                user_info.level === 4 ? null : 
                <button className="btn btn-info btn-sm" onClick={() => setcreateStaffModalOpen(true) } > Create User</button>
              }
              
              
              </div>
          </div>
          </CardHeader>
          <CardBody>
          
            <TabContent activeTab={"tabs1"}>
              <TabPane tabId={"tabs1"}>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Type</th>
                    <th scope="col">Assigned </th>
                    <th scope="col">Closed Tickets</th>
                    <th scope="col">Last login Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                {staffs.length > 0 && staffs  ? (
                      staffs.map((trans, i) => (
                        <tr  key={i}  >
                          <th onClick={(e) =>{ staff_details(trans.id) }} >{trans.name}</th>
                          <td>{trans.email}</td>
                          <td>{(trans.level == 1) ? "CLIENT" : (trans.level == 2) ? "STAFF" : "E2E SUPERADMIN"}</td>
                          <td>{trans.assigned_count}</td>
                          <td>{trans.closed_count}</td>
                          <td>{trans.last_login}</td>
                          
                          <td><> <Button className="btn btn-success" onClick={handleclick.bind(this, trans.id)} class="btn btn-success"><i class="fa fa-edit"></i></Button><Button className="btn btn-danger" onClick={deleterow.bind(this, trans.id)} class="btn btn-danger"><i class="fa fa-trash"></i></Button></></td>
                        </tr>
                      ))
                  ) : (
                   <tr>
                   <td>No Data Found</td>
                   </tr>
                 )}
                </tbody>
              </Table>
              </TabPane>
            
              
            </TabContent>
            <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
          </CardBody>
        </Card>
        </Col>
        </Row>
        
      </Container>
      <Modal className="taskdetailmodal"  isOpen={createstaffModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Create User 
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setcreateStaffModalOpen(!createstaffModalOpen)}>
                <span aria-hidden={true}>×</span>
                <div>
                  
                </div>
              </button>
            </div>
            <ModalBody>
            <Row>
            <Col className="mb-5 mb-xl-0" xl="6">
                      <FormGroup>
                        <label>User Type</label>
                      <select class="form-control"defaultValue={inputFields.level} name="level"   onChange={e => inputhandler(e)}>
                          <option value='1'>Client</option>
                          <option value='2'>Staff</option>
                          <option value='3'>E2E Support Team</option>
                      </select>
                      </FormGroup>
                      </Col>
            <Col className="mb-5 mb-xl-0" xl="6">
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          placeholder="Name"
                          type="text"
                          name="name"
                          value={inputFields.name}
                          onChange={e => inputhandler(e)}
                        />
                      </FormGroup>
                      </Col>
                      
                      </Row>

                      <Row>
                      <Col className="mb-5 mb-xl-0" xl="6">
                        <FormGroup>
                        <label>Email</label>
                          <Input
                            placeholder="Email"
                            type="text"
                            name="email"
                            value={inputFields.email}
                            onChange={e => inputhandler(e)}
                          />
                        </FormGroup>
                        </Col>
                        <Col className="mb-5 mb-xl-0" xl="6">
                        <FormGroup>
                        <label>Password</label>
                          <Input
                            placeholder="Password"
                            type="password"
                            name="password"
                            value={inputFields.password}
                            onChange={e => inputhandler(e)}
                          />
                        </FormGroup>
                        </Col>
                      
                     </Row>
                  <FormGroup>
                  <Button color='success' size="extra-small" onClick={onRespondClick.bind(this)}>
                        Create Query
                    </Button>
                  </FormGroup>
            </ModalBody>
      </Modal>
    </>
  );
};

export default Staff;