import React, { useEffect, useState } from "react";
import ReactDatetime from "react-datetime";
import classnames from "classnames";
import { Doughnut } from "react-chartjs-2";
import { apiUrl, domainUrl, ameobapath, ameobaapipath } from "../../constant";
import "./style.css";
import { Reportgraphicservice } from "../../services/reportgraphicservice";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Progress,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  CardText,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

function Reports() {
  let initialStateData = {
    data: [],
    message: {
      Variance: 0,
      InvoicesProcessed: 0,
      InvoicesinQueries: 0,
    },
  };
  var date = moment();

  var currentDate = date.format("YYYY-MM-DD");
  const [submitstatus, setSubmitstatus] = useState(false);
  const [AppCredentials, setAppCredentials] = useState([]);
  const [state, setState] = useState({
    startDate: currentDate,
    endDate: currentDate,
    url_to_hit: "ExecutiveSummary",
    tenet_id: "",
    periods: "",
    number: "",
    paymenttype: "",
  });
  // const [periods, setPeriods] = useState();
  // const [number, setNumber] = useState();
  // const [paymenttype, setPaymenttype] = useState();
  const [tabs, setTabs] = useState(1);
  const [dashboardGraph, setDashboardGraph] = useState(initialStateData);
  const [graphheader, setGraphheader] = useState(initialStateData);
  const [Executive, setExecutive] = useState(true);
  const [profitloss, setProfitloss] = useState();
  const [GeneralLedger, setGeneralLedger] = useState();
  const [BalanceSheet, setBalanceSheet] = useState();
  const [activemenu, setActivemenu] = useState("bgcolor");
  const [executiveReport_dynamic, setExecutiveReport_dynamic] =
    useState("bgcolor");
  const [profitLossReport_dynamic, setProfitLossReport_dynamic] = useState("");
  const [balanceReport_dynamic, setBalanceReport_dynamic] = useState("");
  const [generalLedgerReport_dynamic, setGeneralLedgerReport_dynamic] =
    useState("");
  // const [url_to_hit, setUrl_to_hit] = useState("executivereport.php");
  const [comparemonth, setComparemonth] = useState([
    { key: "", value: "", label: "Compare with" },
    { key: "1", value: "MONTH", label: "Month" },
    { key: "2", value: "QUARTER", label: "Quarter" },
    { key: "3", value: "YEAR", label: "Year" },
  ]);
  const [days, setDays] = useState([{ key: "", value: "", label: "Periods" }]);
  const [graphtitle, setGraphtitle] = useState("");
  const [reportname, setReportname] = useState("Executive Summary");

  function get_graph_data(
    start_date = state.startDate,
    end_date = state.endDate,
    tenet_id = state.tenet_id,
    periods = state.periods,
    number = state.number,
    paymenttype = state.paymenttype,
    url_to_hit = state.url_to_hit
  ) {
    Reportgraphicservice.reportdata(
      start_date,
      end_date,
      tenet_id,
      periods,
      number,
      paymenttype,
      url_to_hit
    ).then((response) => {
      console.log(response.data);
      let header = [];
      let data = [];
      let check = "";
      if (response.data.status == "success") {
        let comm = ",";
        let count1 = Object.keys(response.data.header).length;
        for (let item in response.data.header) {
          if (count1 > item) {
            comm = ",";
          } else {
            comm = "";
          }
          header[item - 1] = response.data.header[item];
          //header[item-1]=response.data.header[item];
          count1--;
        }
        let count2 = Object.keys(response.data.chartd).length;
        for (let i in response.data.chartd) {
          check = !isNaN(response.data.chartd[i]);
          if (check) {
            if (count2 > i) {
              comm = ",";
            } else {
              comm = "";
            }
            data[i - 1] = response.data.chartd[i];
          } else {
            setGraphtitle(reportname + " " + response.data.chartd[0]);
          }
          // data+=response.data.chartd[i]+comm;
          count2--;
        }
        console.log([header]);
        console.log(data);
        setGraphheader([header]);
        setDashboardGraph(data);
      } else {
        setGraphheader([header]);
        setDashboardGraph(data);
        setGraphtitle(reportname);
      }
    });
  }

  function setEndDateRange(e) {
    let year = e._d.getFullYear();
    let month = (e._d.getMonth() + 1).toString().padStart(2, "0");
    let day = e._d.getDate().toString().padStart(2, "0");
    let endday = year + "-" + month + "-" + day;
    setState({ ...state, endDate: endday });
    get_graph_data(
      state.startDate,
      state.endDate,
      state.tenet_id,
      state.periods,
      state.number,
      state.paymenttype,
      state.url_to_hit
    );
  }
  function setStartDateRange(e) {
    let year = e._d.getFullYear();
    let month = (e._d.getMonth() + 1).toString().padStart(2, "0");
    let day = e._d.getDate().toString().padStart(2, "0");
    let startday = year + "-" + month + "-" + day;
    setState({ ...state, startDate: startday });
    get_graph_data(
      state.startDate,
      state.endDate,
      state.tenet_id,
      state.periods,
      state.number,
      state.paymenttype,
      state.url_to_hit
    );
  }

  //   const toggleNavs = (e, state, index) => {
  //     e.preventDefault();
  //     setTabs(index);
  //   };

  const gettenetid = () => {
    var bodyFormData = new FormData();
    const token = localStorage.getItem("authtoken");
    const userdata = JSON.parse(localStorage.getItem("user"));
    bodyFormData.append("userId", userdata.id);

    return axios({
      method: "post",
      url: `${apiUrl}/xero/gettenetid`,
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      function (response) {
        console.log(response.data.data.tenet_id);
        if (response.status == 200) {
          setState({ ...state, tenet_id: response.data.data.tenet_id });
        }
      },
      (error) => {
        console(error);
        toast.error(error);
      }
    );
  };

  useEffect(() => {
    gettenetid();
  }, []);

  useEffect(() => {
    get_graph_data();
  }, [state.tenet_id, reportname]);

  let chartExample2 = {
    options: {
      legend: {
        display: true,
        position: "right",
      },
    },
    data: {
      labels: graphheader[0],
      // [
      //   graphheader
      // ],
      datasets: [
        {
          label: "Sales",
          //  data:[dashboardGraph],
          data: [dashboardGraph[0], dashboardGraph[1], dashboardGraph[2]],
          // [
          //   dashboardGraph
          // ],
          backgroundColor: ["#c79cff", "#ffb49b", "#ffed93"],
          borderColor: ["#c79cff", "#ffb49b", "#ffed93"],
          borderWidth: 1,
        },
      ],
    },
  };

  const downloadreport = () => {
    console.warn(state.tenet_id);
    if (state.tenet_id == "") {
      toast.error("Xero not connected..");
      return;
    }
    setSubmitstatus(true);
    var bodyFormData = new FormData();
    const token = localStorage.getItem("authtoken");
    bodyFormData.append("tenet_id", state.tenet_id);
    bodyFormData.append("startdate", state.startDate);
    bodyFormData.append("enddate", state.endDate);
    bodyFormData.append("periods", state.periods);
    bodyFormData.append("number", state.number);
    bodyFormData.append("paymenttype", state.paymenttype);

    if (executiveReport_dynamic) {
      bodyFormData.append("url_to_hit", state.url_to_hit);
    } else if (profitLossReport_dynamic) {
      bodyFormData.append("url_to_hit", state.url_to_hit);
    } else if (balanceReport_dynamic) {
      bodyFormData.append("url_to_hit", state.url_to_hit);
    } else if (generalLedgerReport_dynamic) {
      bodyFormData.append("url_to_hit", state.url_to_hit);
    }

    return axios({
      method: "post",
      url: `${ameobaapipath}/report/getReportsPDFFromXero`,
      // url: `${apiUrl}/xero/ReportsPDFFromXero`,
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(
        function (response) {
          // console.warn(response);return;
          setSubmitstatus(false);
          if (response.status == 200) {
            if (response.data == null) {
              setTimeout(() => toast.error(error), 2000);
            } else {
              if (
                typeof response.data.filepath === "undefined" &&
                typeof response.data.filename === "undefined"
              ) {
                toast.error("Xero account not connected...");
              } else {
                window.location =
                  apiUrl +
                  "/xero/download?file_path=" +
                  response.data.filepath +
                  "&file_name=" +
                  response.data.filename;
              }

              // setTimeout(() => toast.success("successfully downloaded"), 2000);
            }
          } else if (response.data == "null")
            setTimeout(() => toast.error("Not found  data"), 2000);
        },
        (error) => {
          console(error);
          toast.error(error);
        }
      )
      .catch(function (error) {
        toast.error(error);
      });

    // API.call({
    //   path: "/xero/getReportsPDFFromXero",
    //   data: {
    //     tenet_id: tenet_id,
    //     startdate: state.startDate,
    //     url_to_hit: "executivereport.php",
    //   },
    // }).then((response) => {
    //   if (response.status == "success") {
    //     if (response.data == null) {
    //       this.setState({
    //         message: "error",
    //       });
    //       setTimeout(
    //         () =>
    //           this.setState({
    //             message: null,
    //           }),
    //         2000
    //       );
    //     } else {
    //       window.location.href =
    //         apiRoot +
    //         "/xero/download?file_path=" +
    //         response.data.filepath +
    //         "&file_name=" +
    //         response.data.filename;
    //       this.setState({
    //         message: "success",
    //       });
    //       setTimeout(
    //         () =>
    //           this.setState({
    //             message: null,
    //           }),
    //         2000
    //       );
    //     }
    //   } else if (response.data == "null") {
    //     this.setState({
    //       message: "error",
    //     });
    //     setTimeout(
    //       () =>
    //         this.setState({
    //           message: null,
    //         }),
    //       2000
    //     );
    //   } else {
    //     this.setState({
    //       message: "error",
    //     });
    //     setTimeout(
    //       () =>
    //         this.setState({
    //           message: null,
    //         }),
    //       2000
    //     );
    //   }
    // });
  };

  const checkactive = (e) => {
    if (e == "executiveReport") {
      setState({ ...state, url_to_hit: "ExecutiveSummary" });
      setExecutiveReport_dynamic("bgcolor");
      setProfitLossReport_dynamic("");
      setGeneralLedgerReport_dynamic("");
      setBalanceReport_dynamic("");
      setReportname("Executive Report");
    } else if (e == "ProfitLoss") {
      setState({ ...state, url_to_hit: "ProfitAndLoss" });
      setExecutiveReport_dynamic("");
      setProfitLossReport_dynamic("bgcolor");
      setGeneralLedgerReport_dynamic("");
      setBalanceReport_dynamic("");
      setReportname("ProfitLoss Report");
    } else if (e == "GeneralLedger") {
      setState({ ...state, url_to_hit: "" });
      setExecutiveReport_dynamic("");
      setProfitLossReport_dynamic("");
      setBalanceReport_dynamic("");
      setGeneralLedgerReport_dynamic("bgcolor");
      setReportname("General Ledger Report");
    } else if (e == "BalanceSheet") {
      setState({ ...state, url_to_hit: "BalanceSheet" });
      setExecutiveReport_dynamic("");
      setProfitLossReport_dynamic("");
      setGeneralLedgerReport_dynamic("");
      setBalanceReport_dynamic("bgcolor");
      setReportname("Balance Report");
    }
    //get_graph_data(state.startDate,state.endDate,tenet_id,periods,number,paymenttype,url_to_hit);
  };

  const comparewith = (event) => {
    setState({ ...state, periods: event.target.value });
    if (event.target.value == "") {
      setDays([{ key: "", value: "", label: "Periods" }]);
    } else {
      setDays([
        { key: "", value: "", label: "SELECT " },
        { key: "1", value: "1", label: "1" },
        { key: "2", value: "2", label: "2 " },
        { key: "3", value: "3", label: "3 " },
        { key: "4", value: "4", label: "4 " },
        { key: "5", value: "5", label: "5 " },
        { key: "6", value: "6", label: "6 " },
        { key: "7", value: "7", label: "7 " },
        { key: "8", value: "8", label: "8 " },
        { key: "9", value: "9", label: "9 " },
        { key: "10", value: "10", label: "10 " },
        { key: "11", value: "11", label: "11 " },
      ]);
    }
  };
  const getperiods = (event) => {
    // setNumber(event.target.value);
    setState({ ...state, number: event.target.value });
  };

  const common_class = {
    height: "120px",
  padding: "10px",
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
        {executiveReport_dynamic ? (
          <Row>
            <Col xs={12} xl={2}>
              <h2>My Reports For</h2>
            </Col>
            <Col xs={12} xl={3}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Start date",
                    }}
                    timeFormat={false}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        state.startDate &&
                        state.endDate &&
                        state.startDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        state.startDate &&
                        state.endDate &&
                        new Date(state.startDate._d + "") <
                          new Date(currentDate._d + "") &&
                        new Date(state.endDate._d + "") >
                          new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        state.endDate &&
                        state.endDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={(e) => setStartDateRange(e)}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        ) : null}

        {profitLossReport_dynamic ? (
          <Row>
            <Col xs={12} xl={2}>
              <h2>My Reports For</h2>
            </Col>
            <Col xs={12} xl={3}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Start date",
                    }}
                    timeFormat={false}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        state.startDate &&
                        state.endDate &&
                        state.startDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        state.startDate &&
                        state.endDate &&
                        new Date(state.startDate._d + "") <
                          new Date(currentDate._d + "") &&
                        new Date(state.endDate._d + "") >
                          new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        state.endDate &&
                        state.endDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={(e) => setStartDateRange(e)}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs={12} xl={3}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <ReactDatetime
                    inputProps={{
                      placeholder: "End date",
                    }}
                    timeFormat={false}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        state.startDate &&
                        state.endDate &&
                        state.startDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        state.startDate &&
                        state.endDate &&
                        new Date(state.startDate._d + "") <
                          new Date(currentDate._d + "") &&
                        new Date(state.endDate._d + "") >
                          new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        state.endDate &&
                        state.endDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={(e) => setEndDateRange(e)}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs={12} xl={2}>
              <FormGroup>
                {/* <label className="form-control-label" htmlFor="input-email">
                Compare with:
              </label> */}
                <select
                  className="form-control"
                  name=""
                  onChange={(e) => comparewith(e)}
                >
                  {comparemonth.map((data, index) => {
                    // let selected="";
                    // if(data.value == state.periods){
                    //   selected="selected";
                    // }else{
                    //   selected="";
                    // }
                    return (
                      <option value={data.value} key={index}>
                        {data.label}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
            <Col xs={12} xl={2}>
              <FormGroup>
                {/* <label className="form-control-label" htmlFor="input-email">
                Periods
              </label> */}
                <select
                  className="form-control"
                  onChange={(e) => getperiods(e)}
                >
                  {days.map((data, index) => {
                    let selected = "";
                    if (data.value == state.periods) {
                      selected = "selected";
                    } else {
                      selected = "";
                    }

                    return (
                      <option value={data.value} {...selected}>
                        {data.label + " " + state.periods}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
          </Row>
        ) : null}

        {balanceReport_dynamic ? (
          <Row>
            <Col xs={12} xl={2}>
              <h2>My Reports For</h2>
            </Col>
            <Col xl={4} xs={12}>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Start date",
                    }}
                    timeFormat={false}
                    renderDay={(props, currentDate, selectedDate) => {
                      let classes = props.className;
                      if (
                        state.startDate &&
                        state.endDate &&
                        state.startDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " start-date";
                      } else if (
                        state.startDate &&
                        state.endDate &&
                        new Date(state.startDate._d + "") <
                          new Date(currentDate._d + "") &&
                        new Date(state.endDate._d + "") >
                          new Date(currentDate._d + "")
                      ) {
                        classes += " middle-date";
                      } else if (
                        state.endDate &&
                        state.endDate._d + "" === currentDate._d + ""
                      ) {
                        classes += " end-date";
                      }
                      return (
                        <td {...props} className={classes}>
                          {currentDate.date()}
                        </td>
                      );
                    }}
                    onChange={(e) => setStartDateRange(e)}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs={12} xl={3}>
              <FormGroup>
                {/* <label className="form-control-label" htmlFor="input-email">
              Compare with:
            </label> */}
                <select
                  className="form-control"
                  name=""
                  onChange={(e) => comparewith(e)}
                >
                  {comparemonth.map((data, index) => {
                    return (
                      <option value={data.value} key={index}>
                        {data.label}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
            <Col xl={3} xs={12}>
              <FormGroup>
                {/* <label className="form-control-label" htmlFor="input-email">
              Periods
            </label> */}
                <select
                  className="form-control"
                  onChange={(e) => getperiods(e)}
                >
                  {days.map((data, index) => {
                    return (
                      <option value={data.value}>
                        {data.label + " " + state.periods}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col xs={12} xl={12}>
            <div className="nav-wrapper reportnavwrapper">
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 1,
                    })}
                    onClick={(e) => toggleNavs(e, "tabs", 1)}
                    href="#pablo"
                    role="tab"
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem disabled>
                  <NavLink
                    aria-selected={tabs === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 2,
                    })}
                    onClick={(e) => toggleNavs(e, "tabs", 2)}
                    href="#pablo"
                    role="tab"
                  >
                    Financial Reports
                  </NavLink>
                </NavItem>
                <NavItem disabled>
                  <NavLink
                    aria-selected={tabs === 3}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: tabs === 3,
                    })}
                    onClick={(e) => toggleNavs(e, "tabs", 3)}
                    href="#pablo"
                    role="tab"
                  >
                    VAT Reports
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <Card className="shadow">
              <CardBody>
                <TabContent activeTab={"tabs" + tabs}>
                  <TabPane tabId="tabs1">
                    <Row>
                      <Col xs={12}>
                        <div className="downloaddiv">
                          {submitstatus ? (
                            <Button
                              color="primary"
                              disabled
                              style={{
                                fontSize: "20px",
                                padding: "10px 25px",
                                margin: "10px 0",
                              }}
                            >
                              <Spinner size="sm">Download Reports</Spinner>
                              <span>{" Download Reports "}</span>
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="button"
                              id="download-btn"
                              onClick={downloadreport}
                            >
                              Download Reports
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col xs={12} xl={5}>
                        <Row>
                          <Col xs={12} xl={6}>
                            <Card
                              onClick={() => checkactive("executiveReport")}
                              id={executiveReport_dynamic ? "" : "Executive"}
                              className={executiveReport_dynamic}
                              style={common_class}
                            >
                              <CardBody>
                                <CardTitle>Executive Summary</CardTitle>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12} xl={6}>
                            <Card
                              onClick={() => checkactive("ProfitLoss")}
                              id={profitLossReport_dynamic ? "" : "ProfitLoss"}
                              className={profitLossReport_dynamic}
                              style={common_class}
                            >
                              <CardBody>
                                <CardTitle>Profit &<br/> Loss</CardTitle>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12} xl={6}>
                            <Card
                              onClick={() => checkactive("BalanceSheet")}
                              id={balanceReport_dynamic ? "" : "BalanceSheet"}
                              className={balanceReport_dynamic}
                              style={common_class}
                            >
                              <CardBody>
                                <CardTitle>Balance<br/>Sheet</CardTitle>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs={12} xl={6}>
                            <Card
                              // onClick={() => checkactive("GeneralLedger")}
                              id={
                                generalLedgerReport_dynamic
                                  ? ""
                                  : "GeneralLedger"
                              }
                              className={generalLedgerReport_dynamic}
                              style={common_class}
                            >
                              <CardBody>
                                <CardTitle>General <br/> Ledger</CardTitle>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} xl={7}>
                        <Card className="shadow" style={{ height: "255px" }}>
                          <CardBody>
                            <div className="chart">
                              <h1>{graphtitle}</h1>
                              <Doughnut
                                data={chartExample2.data}
                                options={chartExample2.options}
                              />
                            </div>
                          </CardBody>
                        </Card>
                        <br></br>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="tabs2">
                    <p className="description">
                      Cosby sweater eu banh mi, qui irure terry richardson ex
                      squid. Aliquip placeat salvia cillum iphone. Seitan
                      aliquip quis cardigan american apparel, butcher voluptate
                      nisi qui.
                    </p>
                  </TabPane>
                  <TabPane tabId="tabs3">
                    <p className="description">
                      Raw denim you probably haven't heard of them jean shorts
                      Austin. Nesciunt tofu stumptown aliqua, retro synth master
                      cleanse. Mustache cliche tempor, williamsburg carles vegan
                      helvetica. Reprehenderit butcher retro keffiyeh
                      dreamcatcher synth.
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Reports;
