import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  UncontrolledAlert,
  Spinner,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { apiUrl } from "../../constant";
import { userService } from "../../services/authentication";
import "assets/css/custom.css";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { Formloader } from "components/Loader/PageLoader.js";
import { staffmanageService } from "../../services/staffmanageService.js";
import classnames from "classnames";
import ReactCrop from "react-image-crop";
import { Padding } from "@mui/icons-material";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// import './credentialstyle.css';
const axios = require("axios");

const Profile = () => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [error, setError] = useState(null);
  const [uploadbbtn, setUploadbbtn] = useState(false);
  const [successMsg, setsuccessMsg] = useState(null);
  const [rolestatus, setRolestatus] = useState(false);
  const [inputFields, setInputfields] = useState({});
  const [comapanydetails, setComapanydetails] = useState({});
  const [formloader, setformloader] = useState(false);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 16 / 9 });
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [tabs, setTabs] = useState(1);
  const [profilecheck, setProfilecheck] = useState(false);
  const errorDiv = error ? (
    <UncontrolledAlert className="alert-danger" fade={false}>
      {error}
    </UncontrolledAlert>
  ) : (
    ""
  );
  const successDiv = successMsg ? (
    <UncontrolledAlert className="alert-success" fade={false}>
      {successMsg}
    </UncontrolledAlert>
  ) : (
    ""
  );

  const [src, setSrc] = useState("");


  const makeClientCrop = async (crop) => {
    if ((image, crop.width && crop.height)) {
      const croppedImg = await getCroppedImg(image, crop, "newFile.jpeg");
      setCroppedImageUrl(croppedImg);
    }
  };


  const selectImage = (file) => {
    //setSrc(URL.createObjectURL(file));
    // setSrc(file);

    let newEdit = { ...src };

    newEdit = file;
    setSrc(newEdit);
    //uploadspics(newEdit);
  };
  const uploadsfile = (event) => {
    setUploadbbtn(true);
    let token = localStorage.getItem("authtoken");
    var bodyFormData = new FormData();
    bodyFormData.append("id", inputFields.id);
    bodyFormData.append("file", src);
    return axios({
      method: "post",
      url: `${apiUrl}/staff/edit`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        if (response.status == 200) {
          setProfilecheck(true);
          setSrc("");
          setUploadbbtn(false);
          toast.success(response.data.message);
          window.location.reload(false);
        } else {
          setUploadbbtn(false);
          toast.error("something went wrong please try after some time");
        }
      })
      .catch(function (response) {
        setUploadbbtn(false);
        return response;
      });
  };

  const getCroppedImg = (sourceImage, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      sourceImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    try {
      return new Promise((resolve) => {
        canvas.toBlob((file) => {
          resolve(URL.createObjectURL(file));
        }, "image/jpeg");
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };



  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }
  function companyhandler(e){
    setComapanydetails({ ...comapanydetails, [e.target.name]: e.target.value });
  }
  const [paymentData, setpaymentData] = useState({
    plan_selected: "",
    amount_paid: "",
    amount_currency: "",
    plan_type: "",
    end_date_d: "",
    end_date_rem: "",
    end_date_sup: "",
    start_date_d: "",
    start_date_rem: "",
    start_date_sup: "",
    receipt_url: "",
  });
  const get_staff_data = async () => {
    staffmanageService.getstaff(user_info.id).then((response) => {
      setInputfields(response.data.data);
      setSrc(response.data.data.profilePic);
      localStorage.setItem('getprofile',response.data.data.profilePic);
      setpaymentData(response.data.data.payment_data);

      // setSrc(response.data.data.profilePic)
    });
  };
  function get_invoice_details(){
    userService.invoicebankdetail().then((response) => {
      console.log(response);
      if (response.status == 200) {
        let result=response.data.data;
        setComapanydetails({ ...comapanydetails,bank_name:result.bank_name == 'null' ? "" :result.bank_name,
          ...comapanydetails,bank_account_name:result.bank_account_name == 'null' ? "" :result.bank_account_name,
          ...comapanydetails,bank_account_no:result.bank_account_no== 'null' ? "" :result.bank_account_no,
          ...comapanydetails,bank_sort_code:result.bank_sort_code== 'null' ? "" :result.bank_sort_code,
          ...comapanydetails,company_registration_no:result.company_registration_no == 'null' ? "" :result.company_registration_no,
          ...comapanydetails,company_vat_no:result.company_vat_no == 'null' ? "" :result.company_vat_no,
          ...comapanydetails,address:result.address== 'null' ? "" :result.address,
          ...comapanydetails,country:result.country=='null' ? "" :result.country,
          ...comapanydetails,state:result.state=='null' ? "" :result.state,
          ...comapanydetails,city:result.city=='null' ? "" :result.city,
          ...comapanydetails,invoice_email:result.invoice_email=='null' ? "" :result.invoice_email,
          ...comapanydetails,phone:result.phone=='null' ? "" :result.phone,
          ...comapanydetails,zip_code:result.zip_code=='null' ? "" :result.zip_code,
        })
      } else {
        setformloader(false);
        toast.error(response.data.message);
        setsuccessMsg(null);
      }
    });
  }
  useEffect(() => {
    get_staff_data();
    get_invoice_details();
  }, [profilecheck]);
  function profileupdate(e) {
    e.preventDefault();
    setformloader(true);
    userService
      .updateprofile(
        inputFields.id,
        inputFields.corient_vat_filing_quarter,
        inputFields.password,
        inputFields.confirm_password
      )
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          setformloader(false);
          toast.success(response.data.message);
        } else {
          setformloader(false);
          toast.error(response.data.message);
          // setError('Enter All required field');
          setsuccessMsg(null);
          // alert(response.data.message);
        }
      });
  }

  function bankdetailsupdate(e) {
    e.preventDefault();
    setformloader(true);
    console.log(comapanydetails);

    userService
      .updatebankdetails(
        comapanydetails.bank_name,
        comapanydetails.bank_account_name,
        comapanydetails.bank_account_no,
        comapanydetails.bank_sort_code,
        comapanydetails.company_registration_no,
        comapanydetails.company_vat_no,
        inputFields.email,
        comapanydetails.address,
        comapanydetails.country,
        comapanydetails.state,
        comapanydetails.city,
        comapanydetails.phone,
        comapanydetails.invoice_email,
        comapanydetails.zip_code, 
      )
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          setformloader(false);
          toast.success("company details updated successfully..");
        } else {
          setformloader(false);
          toast.error("something went wrong please try again");
        }
        get_invoice_details();
      });
  }
  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setTabs(index)
  };

  // const role=()=>{

  //   if(JSON.parse(localStorage.getItem('user-info')) != null){
  //     user_info = JSON.parse(localStorage.getItem('user-info'));  
  //       if(user_info.rolename=='superadmin'){
  //         setRolestatus(true);
  //       }else{
  //         setRolestatus(false);
  //       }
  //     }
  // }
  return (
    <>
      <UserHeader />
      {/* Page content */}

      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo">
                      <img alt="..." class="rounded-circle" 
                      src={src ? `data:image/png;base64,${src}` :require('assets/img/user/dummy.png')}
                      // src={URL.createObjectURL(src)}
                       />
                    </a>
                    <div></div>
                    <center>
                      {/* <div>
          {src && (
            <div>
              <ReactCrop src={src}
              onImageLoaded={ (img) => {
                setImage(img);
            }}
                crop={crop} 
                onComplete={(crop) => image? makeClientCrop(crop): console.log("wait")}
                onChange={(cropData) => setCrop(cropData)}
                 />
              <br />
              <button onClick={cropImageNow}>Crop</button>
            </div>
          )}
        </div> */}
                      <div></div>
                      {/* <div>{output && <img src={output} />}</div> */}
                    </center>
                    {/* <ReactCrop
                // src={inputFields.profilePic}
                src="https://app.e2eaccounting.com/api/files/p_bbe240_cropped.jpg"
                className="rounded-circle"
                crop={crop}
                ruleOfThirds
                onImageLoaded={ (img) => {
                    console.log(img);
                    setImage(img);
                }}
                onComplete={(crop) => image? makeClientCrop(crop): console.log("wait")}
                onChange={(cropData) => setCrop(cropData)}
            /> */}
                    {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                    {/* <img
                        alt="..."
                       
                        src="https://app.e2eaccounting.com/api/files/p_bbe240_cropped.jpg"
                      /> */}
                    {/* </a> */}
                  </div>
                </Col>
              </Row>

              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <p> &nbsp;</p>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>{inputFields.name}</h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {inputFields.email}
                  </div>
                  {user_info.level == 1 ? (
                    <>
                      <div className="h5 mt-4">
                        <i className="ni business_briefcase-24 mr-2" />
                        Company Name : {inputFields.company_name}
                        <br></br>
                        <span>
                          VAT Register : {inputFields.is_vat_registered}
                        </span>
                        <br></br>
                        <span>
                          VAT Filling Frequency :{" "}
                          {inputFields.corient_vat_filing_frequency}
                        </span>
                        {/* <br></br>
                  <span>
                     Financial Year End : {inputFields.financial_year_end}
                  </span> */}
                      </div>
                      <hr className="my-4" />
                      <h3>Current Payment Plan</h3>
                      <div className="h5 mt-4 font-weight-300">
                        <span>
                          <b class="font-weight-600">Plan Name : </b>
                          {paymentData.plan_selected}{" "}
                        </span>
                        <br></br>
                        <span>
                          <b class="font-weight-600">Plan Type : </b>
                          {paymentData.plan_type}
                        </span>
                        <br></br>
                        <span>
                          <b class="font-weight-600">Start Date : </b>{" "}
                          {paymentData.start_date_d +
                            paymentData.start_date_sup +
                            paymentData.start_date_rem}
                        </span>
                        <br></br>
                        <span>
                          <b class="font-weight-600">End Date : </b>
                          {paymentData.end_date_d +
                            paymentData.end_date_sup +
                            paymentData.end_date_rem}
                        </span>
                        <br></br>
                        <span>
                          <b class="font-weight-600">Amount Paid : </b>
                          {inputFields.amount_paid}
                        </span>
                        <br></br>
                        <span>
                          <b class="font-weight-600">Currency Paid In : </b>
                          {inputFields.amount_currency}
                        </span>
                        <br></br>
                        <a href={inputFields.receipt_url} target="_blank">
                          View Receipt
                        </a>
                      </div>
                    </>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
          <div className="nav-wrapper">
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={tabs === 1}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === 1
                })}
                onClick={e => toggleNavs(e, "tabs", 1)}
                href="#pablo"
                role="tab"
              >
                Profile
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                aria-selected={tabs === 2}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === 2
                })}
                onClick={e => toggleNavs(e, "tabs", 2)}
                href="#pablo"
                role="tab"
              >
                {/* <i className="ni ni-bell-55 mr-2" /> */}
                Bank Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={tabs === 3}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: tabs === 3
                })}
                onClick={e => toggleNavs(e, "tabs", 3)}
                href="#pablo"
                role="tab"
              >
                {/* <i className="ni ni-calendar-grid-58 mr-2" /> */}
                Address
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <Card className="">
          <CardBody>
            <TabContent activeTab={"tabs" + tabs}>
              <TabPane tabId="tabs1">
              <Card className="bg-secondary">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      {errorDiv}
                      {successDiv}
                      <Form onSubmit={profileupdate}>
                        {/* <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6> */}
                        <div className="pl-lg-4">
                          <Row className="justify-content-md-center">
                            <Col lg="12">
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Profile Picture
                              </label>
                              <div class="input-group mb-3">
                                <input
                                  type="file"
                                  class="form-control"
                                  accept="image/*"
                                  onChange={(e) => {
                                    selectImage(e.target.files[0]);
                                  }}
                                />
                                <div class="input-group-append">
                                  { uploadbbtn ?  <Button
                                color="primary"
                                disabled>
                                <Spinner size="sm">Upload</Spinner>
                                <span>{"Upload"}</span>
                              </Button> :
                                  <button class="btn btn-primary" type="button" onClick={uploadsfile}>
                                    Upload
                                  </button>
}
       
                                </div>
                              </div>
                            </Col>

                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  VAT Filling Quarter
                                </label>
                                <select
                                  className="form-control"
                                  name="corient_vat_filing_quarter"
                                  onChange={inputhandler}
                                  value={inputFields.corient_vat_filing_quarter}
                                >
                                  <option value="" selected="selected"></option>
                                  <option value="[3,6,9,12]">
                                    Quarter Ending March,June,September,December
                                  </option>
                                  <option value="[4,7,10,1]">
                                    Quarter Ending April,July,October,January
                                  </option>
                                  <option value="[5,8,11,2]">
                                    Quarter Ending May,August,November,February
                                  </option>
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  New Password
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id="input-first-name"
                                  placeholder="Must be at least 10 characters long"
                                  onChange={inputhandler}
                                  type="password"
                                  name="password"
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Confirm Password
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue=""
                                  id="input-last-name"
                                  placeholder="Confirm new password"
                                  type="password"
                                  name="confirm_password"
                                  onChange={inputhandler}
                                  required
                                />
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Password confirmation should match the input
                                  in the “New Password” field.
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success mt-4"
                              onClick={profileupdate}
                              disabled={formloader}
                            >
                              Update{" "}
                              {formloader ? (
                                <Formloader
                                  height={20}
                                  color="#ffffff"
                                ></Formloader>
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
              </TabPane>
              <TabPane tabId="tabs2">
              <Card className="bg-secondary">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0"> Company Bank Details</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                      {errorDiv}
                      {successDiv}
                      <Form onSubmit={bankdetailsupdate}>
                        {/* <h6 className="heading-small text-muted mb-4">Bank details </h6> */}
                          <Row className="justify-content-md-center">
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="bank_name">
                                  Bank Name
                                </label>
                                <input className="form-control"
                                  name="bank_name" onChange={companyhandler} value={comapanydetails.bank_name} placeholder="enter bank name"/>
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="bank_account_name">
                                  Bank account name
                                </label>
                                <input className="form-control"
                                  name="bank_account_name" onChange={companyhandler} value={comapanydetails.bank_account_name}/>
                              </FormGroup>
                            </Col>

                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="bank_account_no">
                                  Bank account no
                                </label>
                                <input className="form-control"
                                  name="bank_account_no" onChange={companyhandler} value={comapanydetails.bank_account_no}/>
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="bank_sort_code">
                                  Bank sort code
                                </label>
                                <input className="form-control"
                                  name="bank_sort_code" onChange={companyhandler} value={comapanydetails.bank_sort_code}/>
                              </FormGroup>
                            </Col>

                          </Row>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success mt-4"
                              onClick={bankdetailsupdate}
                              disabled={formloader}
                            >
                              Update{" "}
                              {formloader ? (
                                <Formloader
                                  height={20}
                                  color="#ffffff"
                                ></Formloader>
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        
                      </Form>
                    
                  </div>
                </Form>
              </CardBody>
            </Card>
              </TabPane>

              <TabPane tabId="tabs3">
              <Card className="bg-secondary">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0"> Address Details</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                      {errorDiv}
                      {successDiv}
                      <Form onSubmit={bankdetailsupdate}>
                        {/* <h6 className="heading-small text-muted mb-4">Bank details </h6> */}
                          <Row className="justify-content-md-center">
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="company_registration_no">
                                 Company registration no
                                </label>
                                <input className="form-control"
                                  name="company_registration_no" onChange={companyhandler} value={comapanydetails.company_registration_no} placeholder="enter bank name"/>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="company_vat_no">
                                  Company vat no
                                </label>
                                <input className="form-control"
                                  name="company_vat_no" onChange={companyhandler} value={comapanydetails.company_vat_no}/>
                              </FormGroup>
                            </Col>

                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="address">
                                 Address
                                </label>
                                
                                <textarea className="form-control" name="address" onChange={companyhandler} value={comapanydetails.address}/>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="Country">
                                 Country
                                </label>
                                <input className="form-control"
                                  name="country" onChange={companyhandler} value={comapanydetails.country}/>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="state">
                                 State
                                </label>
                                <input className="form-control"
                                  name="state" onChange={companyhandler} value={comapanydetails.state}/>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="city">
                                 City
                                </label>
                                <input className="form-control"
                                  name="city" onChange={companyhandler} value={comapanydetails.city}/>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="invoice_email">
                                 Invoice email
                                </label>
                                <input className="form-control"
                                  name="invoice_email" onChange={companyhandler} value={comapanydetails.invoice_email}/>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="phone">
                                 Phone
                                </label>
                                <input className="form-control"
                                  name="phone" onChange={companyhandler} value={comapanydetails.phone}/>
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="zip_code">
                                 Zip Code
                                </label>
                                <input className="form-control"
                                  name="zip_code" onChange={companyhandler} value={comapanydetails.zip_code}/>
                              </FormGroup>
                            </Col>

                          </Row>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-success mt-4"
                              onClick={bankdetailsupdate}
                              disabled={formloader}
                            >
                              Update{" "}
                              {formloader ? (
                                <Formloader
                                  height={20}
                                  color="#ffffff"
                                ></Formloader>
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        
                      </Form>
                    
                  </div>
                </Form>
              </CardBody>
            </Card>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
        </Col>

        </Row>
      </Container>
    </>
  );
};

export default Profile;

