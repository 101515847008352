import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  InputGroup,
  FormControl,
  InputGroupText,
  Input,
  Modal, 
  ModalHeader, 
  ModalBody,
   ModalFooter
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Emaillogsservice } from "../../services/emaillogsservice";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import './style.css';




function Emaillogs() {
  const [resultdata, setResultdata] = useState([]);
  const [filterText, setFilterText] = React.useState('');
  const [modal, setModal] = useState(false);
  const [emailbody, setEmailbody] = useState("");
  const [pending, setPending] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);


  // const fetchUsers = async (page, size = perPage) => {
  //   setLoading(true);

  //   const response = await axios.get(
  //     `https://reqres.in/api/users?page=${page}&per_page=${size}&delay=1`
  //   );

  //   setData(response.data.data);
  //   setTotalRows(response.data.total);
  //   setLoading(false);
  // };

  async function get_email_logs(page, size = perPage) {
    setLoading(true);
     Emaillogsservice.emaillogs(page, size = perPage,filterText).then((response) => {
      if (response.status == 200) {
        const result = response.data.data;
        console.log(result);
        //setResultdata(result.emaillogs);
        // const filteredItems = result.emaillogs.data.filter(
        //   item => item.subject && item.subject.toLowerCase().includes(filterText.toLowerCase()),
        // );
        // console.warn(filteredItems);
        console.warn(result.emaillogs.total);
        setData(result.emaillogs.data);
        setTotalRows(result.emaillogs.total);
        setLoading(false);
       
      } 
    });
  }

  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === 'remove') {
        return <></>;
      }
    },
  };

  const columns = [
    // {
    //   name: "sno",
    //   selector: (row,index) => index,
    //   maxWidth: "20px"
    // },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email to",
      selector: (row) => row.email_to,
      sortable: true,
      wrap: true,
    },
    {
      name: "BCC",
      selector: (row) => row.bcc,
      wrap: true,
    },
    {
      name: "CC",
      selector: (row) => row.cc,
      wrap: true,
    },
    {
      name: "Sent at",
      selector: (row) => row.sent_at,
      sortable: true,
      wrap: true,
    },
    {
      name: "View",
      selector: (row) =><Button color="info" onClick={()=>viewdetails(row.id)}>View</Button> ,
      wrap: true
    },
  ];

const viewdetails=(id)=>{
  Emaillogsservice.getemaillogs_body(id).then((response) => {
    if (response.status == 200) {
      const result = response.data.data;
      console.log(result);
      console.log(result.emailbody[0].message);
      setEmailbody(result.emailbody[0].message);
    } 
  });
  setModal(!modal);

}

  const convertArrayOfObjectsToCSV=(array)=> {
    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  const downloadCSV = (array) =>{
    console.log(array);
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "emaillogs.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  // const actionsMemo = React.useMemo(
  //   () => <Export onExport={() => downloadCSV(resultdata)} />,
  //   []
  // );
  useEffect(() => {
    get_email_logs(1);
  }, [filterText,perPage]);


  const filter =(e) =>{
    setFilterText(e);
  }


  const handlePageChange = page => {
    get_email_logs(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    get_email_logs(page, newPerPage);
    setPerPage(newPerPage);
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader>
                <div className="justify-content-end row">
                <Col xl="12" className="align-items-center">
                <h2 className="header-title">Email Logs</h2>
                  </Col>
                  </div>
                  <div className="justify-content-end row">
                  <Col xl="6" className="align-items-center">
                  <InputGroup>
                    <Input onChange={(e)=>filter(e.target.value)} filterText={filterText} placeholder="enter your search term ..."/>
                    <Button  color="primary">Search</Button>
                  </InputGroup>
                  </Col>
                  <Col xl="2" className="align-items-center"> <Button  color="success" onClick={()=>downloadCSV(data)}>Export</Button></Col>
                 </div>
                
              </CardHeader>
              <CardBody>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  defaultSortField="subject"
                  progressPending={loading}
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                 
                />
                <CardFooter className="py-4"></CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={viewdetails} >
        <ModalHeader toggle={viewdetails}></ModalHeader>
        <ModalBody>
         {parse(emailbody, options)}

         
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" onClick={viewdetails}>
            Do Something
          </Button>{' '}
          <Button color="secondary" onClick={viewdetails}>
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
    </>
  );
}






export default Emaillogs;
