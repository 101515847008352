import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Progress,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
// core components
import { Doughnut } from "react-chartjs-2";
import { dashboardmanageService } from "../../services/dashboardmanageService.js";

const Index = (props) => {
  let initialStateData = {
    data: [],
    message: {
      Documentstobeprocessed: 0,
      InvoicesProcessed: 0,
      InvoicesinQueries: 0,
    },
  };
  const [dashboardGraph, setdashboardGraph] = useState(initialStateData);
  const [state, setState] = useState({ startDate: null, endDate: null });
  function get_graph_data(
    start_date = state.startDate,
    end_date = state.endDate
  ) {
    dashboardmanageService
      .getUploadedFiles(start_date, end_date, 10)
      .then((response) => {
        setdashboardGraph(response.data);
      });
  }
  function setEndDateRange(e) {
    setState({ ...state, endDate: e });
    get_graph_data(state.startDate._d, e._d);
  }
  function setStartDateRange(e) {
    setState({ ...state, startDate: e });
  }

  let initialData = {
    balance: 0,
    payable: 0,
    receivable: 0,
  };
  const [dashboardStatistics, setdashboardStatistics] = useState(initialData);
  function get_dashboard_data() {
    dashboardmanageService.getDashboardStatistics().then((response) => {
      setdashboardStatistics(response.data.data);
    });
  }
  const [recentTrans, setrecentTrans] = useState([]);
  function get_recent_trans() {
    var url_to_hit = "banktransaction.php";
    dashboardmanageService.getRecentTrans(url_to_hit).then((response) => {
      if (response) {
        setrecentTrans(response.data.data.data);
      }
    });
  }

  useEffect(() => {
    get_graph_data();
    get_dashboard_data();
    get_recent_trans();
  }, []);

  let chartExample2 = {
    options: {
      legend: {
        display: true,
        position: "right",
      },
    },
    data: {
      labels: [
        "Invoices in queries",
        "Documents to be processed",
        "Invoices Processed",
      ],
      datasets: [
        {
          label: "Sales",
          data: [
            dashboardGraph.message.InvoicesinQueries,
            dashboardGraph.message.Documentstobeprocessed,
            dashboardGraph.message.InvoicesProcessed,
          ],
          // backgroundColor: [
          //   'rgba(255, 99, 132, 0.2)',
          //   'rgba(54, 162, 235, 0.2)',
          //   'rgba(255, 206, 86, 0.2)',
          // ],
          backgroundColor: ["#c79cff", "#ffb49b", "#ffed93"],
          borderColor: ["#c79cff", "#ffb49b", "#ffed93"],
          borderWidth: 1,
        },
      ],
    },
  };

  const [tabstate, settabState] = useState({ tabs: 2 });

  const toggleNavs = (e, tabstate, index) => {
    e.preventDefault();
    settabState({
      [tabstate]: index,
    });
  };

  const user_info = JSON.parse(localStorage.getItem("user-info"));
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col xs={12} xl={2}>
            <h2>Dashboard</h2>
          </Col>
          <Col xs={12} xl={4}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Start date",
                  }}
                  timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      state.startDate &&
                      state.endDate &&
                      state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      state.startDate &&
                      state.endDate &&
                      new Date(state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      state.endDate &&
                      state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={(e) => setStartDateRange(e)}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={12} xl={4}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "End date",
                  }}
                  timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      state.startDate &&
                      state.endDate &&
                      state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      state.startDate &&
                      state.endDate &&
                      new Date(state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      state.endDate &&
                      state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  onChange={(e) => setEndDateRange(e)}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="mb-5 mb-xl-0" xl="7">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="mb-0">Statistics</h2>
                  </div>
                </Row>
                <br></br>
                <Row>
                  <Col lg="6" xl="4" style={{ textAlign: "center" }}>
                    <Card className="card-stats mb-4 mb-xl-0 card-1">
                      <Row>
                        <div className="col">
                          <span className="h2 font-weight-bold mb-0">
                            {dashboardStatistics.balance}
                          </span>
                        </div>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm"> */}
                      <p className="mb-0 text-muted text-sm">
                        <span>Bank Balance</span>
                      </p>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4" style={{ textAlign: "center" }}>
                    <Card className="card-stats mb-4 mb-xl-0 card-2">
                      <Row>
                        <div className="col">
                          <span className="h2 font-weight-bold mb-0 ">
                            {dashboardStatistics.receivable}
                          </span>
                        </div>
                      </Row>
                      <p className=" mb-0 text-muted text-sm">
                        <span>Aged receivable</span>
                      </p>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4" style={{ textAlign: "center" }}>
                    <Card className="card-stats mb-4 mb-xl-0 card-3">
                      <Row>
                        <div className="col">
                          <span className="h2 font-weight-bold mb-0">
                            {dashboardStatistics.payable}
                          </span>
                        </div>
                      </Row>
                      <p className="mb-0 text-muted text-sm">
                        <span>Aged payable</span>
                      </p>
                    </Card>
                  </Col>
                </Row>
                <br></br> <br></br>
                <Row>
                  <Col lg="6" xl="4" style={{ textAlign: "center" }}>
                    <Card className="card-stats mb-4 mb-xl-0 card-4">
                      <Row>
                        <div className="col">
                          <span className="h2 font-weight-bold mb-0">
                            {dashboardGraph.message.Documentstobeprocessed}
                          </span>
                        </div>
                      </Row>
                      <p className="mb-0 text-muted text-sm">
                        <span>Documents to be processed</span>
                      </p>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4" style={{ textAlign: "center" }}>
                    <Card className="card-stats mb-4 mb-xl-0 card-5">
                      <Row>
                        <div className="col">
                          <span className="h2 font-weight-bold mb-0">
                            {dashboardGraph.message.InvoicesProcessed}
                          </span>
                        </div>
                      </Row>
                      <p className="mb-0 text-muted text-sm">
                        <span className="text-nowrap">
                          Invoices <br></br>Processed
                        </span>
                      </p>
                    </Card>
                  </Col>
                  <Col lg="6" xl="4" style={{ textAlign: "center" }}>
                    <Card className="card-stats mb-4 mb-xl-0 card-6">
                      <Row>
                        <div className="col">
                          <span className="h2 font-weight-bold mb-0">
                            {dashboardGraph.message.InvoicesinQueries}
                          </span>
                        </div>
                      </Row>
                      <p className="mb-0 text-muted text-sm">
                        <span className="text-nowrap">
                          Invoices <br></br>in queries
                        </span>
                      </p>
                    </Card>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
          <Col xl="5">
            <Card className="shadow" style={{ height: "230px" }}>
              <CardBody>
                <div className="chart">
                  <Doughnut
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
            <br></br>
            {user_info.level === 1 ? (
              <Card className="shadow">
                <CardBody>
                  <h1 style={{ textAlign: "center" }}>
                    {" "}
                    <Link to="/admin/Uploads">Upload Files</Link>{" "}
                  </h1>
                </CardBody>
              </Card>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader>
                <div className="col-md-6">
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="tabs-icons-text"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        aria-selected={tabstate.tabs === 2}
                        className={classnames("mb-sm-1 mb-md-0", {
                          active: tabstate.tabs === 2,
                        })}
                        onClick={(e) => toggleNavs(e, "tabs", 2)}
                        href="#pablo"
                        role="tab"
                      >
                        Recent Invoices
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-selected={tabstate.tabs === 1}
                        className={classnames("mb-sm-1 mb-md-0", {
                          active: tabstate.tabs === 1,
                        })}
                        onClick={(e) => toggleNavs(e, "tabs", 1)}
                        href="#pablo"
                        role="tab"
                      >
                        Recent Transactions
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </CardHeader>
              <CardBody>
                <TabContent activeTab={"tabs" + tabstate.tabs}>
                  <TabPane tabId="tabs1">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Description</th>
                          <th scope="col">Reference</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recentTrans.length > 0 && recentTrans ? (
                          recentTrans.map((trans, i) => (
                            <tr key={i}>
                              <th scope="row">{trans.date}</th>
                              <td>{trans.description}</td>
                              <td>{trans.reference}</td>
                              <td>{trans.amount}</td>
                              <td>{trans.type}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                  <TabPane tabId="tabs2">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">File Name</th>
                          <th scope="col">File Status</th>
                          <th scope="col">Upload Date and Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {typeof dashboardGraph.data !== "undefined" &&
                        dashboardGraph.data.length > 0 &&
                        dashboardGraph.data ? (
                          dashboardGraph.data.map((invoice, i) =>
                            i < 10 ? (
                              <tr key={i}>
                                <th scope="row">{invoice.file_name}</th>
                                <td>{invoice.file_case_status}</td>
                                <td>{invoice.created_at}</td>
                              </tr>
                            ) : null
                          )
                        ) : (
                          <tr>
                            <td colSpan={3}>
                              Please select a date range. Upon selection of the
                              date range, <br></br>you will be able to see the
                              files uploaded<br></br> within the selected date
                              range
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
