
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const staffmanageService = {
  getstaffs,
  deleteuser,
  getsingleuser,
  updateuser,
  getstaff
};

function getstaffs(page,company){
  let filters = '';
  if(company){
     filters = `&search=${company}`;
  }
  return axios({
      method: "get",
      url: `${apiUrl}/get-staffs?page=${page}${filters}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getstaff(id){
  
  return axios({
      method: "get",
      url: `${apiUrl}/staff/get?id=${id}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function deleteuser(id){
  return axios({
    method: "delete",
    url: `${apiUrl}/staff/${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function getsingleuser(user_id){
  return axios({
    method: "get",
    url: `${apiUrl}/staff/get?id=`+user_id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function updateuser(data){
  var bodyFormData = new FormData();
  bodyFormData.append("id", data.id);
  bodyFormData.append("is_vat_registered", data.is_vat_registered);
  bodyFormData.append("financial_year_end", data.financial_year_end);
  bodyFormData.append("corient_vat_filing_frequency", data.corient_vat_filing_frequency);
  bodyFormData.append("corient_vat_filing_quarter", data.corient_vat_filing_quarter);
  if(data.password != '' || data.confirm_password != ''){
    bodyFormData.append("password", data.password);
    bodyFormData.append("confirm-password", data.confirm_password);
  }
  return axios({
      method: "post",
      url: `${apiUrl}/staff/update`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      return response;
    }); 
}