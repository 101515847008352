import { Link,useLocation } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import {userService} from '../../services/authentication';
import { useEffect,useState } from 'react';
import { useHistory } from "react-router-dom";
import 'assets/css/custom-css.css';
import 'assets/css/themecustom-style.css';
import { connect } from "react-redux";

const AdminNavbar = (props) => {

  const [notifymodel,setNotifymodel] = useState(false);
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const location = useLocation();
  function signout(){
    userService.logout().then((response)=>{
      if(response.status == 200 && response.data.success == true){
        window.location.href = '/';
     }
    }); 
  }

  const styles = {
    'color':'#4a4444',
  };
  return (
    <>
      <Navbar className="navbar-top navbar-dark navbar-custom-style" expand="md" id="navbar-main">
        <Container fluid>
         
           <div className="h4 mb-0 " >
            <div className="clock-style">
                {/* <h1 >Welcome {user_info.name}</h1> */}
                {/* <Notificationcomponent/> */}
            </div>
          </div>  
          {/* </Link> */}
         
          <Nav className="align-items-center d-none d-md-flex customprofile" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle" style={{ background : '#fff' }}>
                   
                      <i class="ni ni-single-02 text-primary"></i>
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm text-primary font-weight-bold">
                    {user_info.name}
                    {/* <br></br> 
                         
                    <small className="text-dark">Fiotect pvt.ltd. </small>  */}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={signout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
       
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  name: state.companysearch.name,
});
const mapDispatchToProps = (dispatch) => {
  return {
    searchcompany: (e) => {
     if(e.target.value != ''){
          if(e.key === 'Enter'){
            e.preventDefault();
            var company_name = document.getElementById('companydata');
            if(company_name.value.length <= 1){
              var company_name = document.getElementById('companydatas');
            }
            dispatch({
              type: "search",
              payload: company_name.value
            })
        }
     }else{
      var company_name = document.getElementById('companydata');
      if(company_name.value.length <= 1){
        var company_name = document.getElementById('companydatas');
      }
      dispatch({
        type: "search",
        payload: company_name.value
      })
     }   
    }
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(AdminNavbar);
