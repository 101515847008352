import React,{useState} from "react";
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { render } from 'react-dom';
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import adminroutes from "adminroutes.js";
import routes from "routes.js";
import {userService} from '../services/authentication';
import { TailSpin } from  'react-loader-spinner'
import PageLoder from 'components/Loader/PageLoader.js'; 

import {gettoken,onMessageListener} from '../firebaseapp';
import { ToastContainer, toast } from 'react-toastify';


const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const history  = useHistory();

  const [isloggedin,setlogged] = useState(false);
  const [isloaded,setloading]  = useState(false);

  // firebase
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  gettoken(setTokenFound);

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({title: payload.notification.title, body: payload.notification.body})
    // console.log(payload);
    // toast.success(payload.notification.title + payload.notification.body,{ delay: 5000 });
  }).catch(err => console.log('failed: ', err));

  // firebase-end
function getprofile(){
  var data=localStorage.getItem("user-info", JSON.stringify(user_info));
  localStorage.setItem("getprofile", JSON.parse(data).profile_pic);
}

  
  React.useEffect(() => {
    checkauth();
    getprofile();
  }, [location]);


  function checkauth(){
    userService.check_auth().then((response)=>{
     if(response.status === 201 || response == 201){
        setloading(false);
        setlogged(false);
        localStorage.clear();
        // history.push('/auth');
      }else{
        setloading(true);
        setlogged(true);
        // setlogged(true); 
      }
    });
  }
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" ) {
        if(location.pathname=='/admin'){
          return( <Switch>
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
              
            />
            <Redirect to="/admin/analytics_report" />
          </Switch>
         );
        }else{
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
        }
      } else {
        
           return null;
           
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < adminroutes.length; i++) {
      if (
        props.location.pathname.indexOf(adminroutes[i].layout + adminroutes[i].path) !==
        -1
      ) {
        return adminroutes[i].name;
      }
    }
    return "Brand";
  };

  if(!isloaded && !isloggedin){
      return(
        <>
          <PageLoder/>
          {/* <TailSpin color="#00BFFF" height={80} width={80} /> */}
        </>
      );
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: localStorage.getItem("getprofile"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(adminroutes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        {/* <Container fluid>
          <AdminFooter />
        </Container> */}
      </div>
    </>
  );
};

export default Admin;
