import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, domainUrl } from "../../constant";
import  "./style.css";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  FormGroup,
  Input,
} from "reactstrap";


function Invoicedetails() {
  const [AppCredentials, setAppCredentials] = useState([]);
  const [invoiceAppUserName, setInvoiceAppUserName] = useState();
  const [invoiceAppPassword, setInvoiceAppPassword] = useState();
  const [invoiceAppURL, setInvoiceAppURL] = useState();
  const [loader, setLoader] = useState(true);

  const token = localStorage.getItem("authtoken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };



  useEffect(() => {
    
    const url = `${apiUrl}/Getinvoicinglogin`;
    const fetchData = async () => {
      try {
        const response = await fetch(url, config);
        const json = await response.json();
        const invoicing_app_usrname = encodeURIComponent(
          json.data.invoicing_app_usrname
        );
        const invocing_app_password = json.data.invocing_app_password;
        const invocing_app_url = json.data.invocing_app_url;
        setLoader(false);
        document.getElementById("invoicingApp").src =
          invocing_app_url +
          "login?username=" +
          invoicing_app_usrname +
          "&password=" +
          invocing_app_password;
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  const reloadpage = ()=>{
    location.reload()
  }
  
  return (
  
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
     
      <Container className="mt--7" fluid>
        <Row> 
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card style={{padding: "0px"}}>
              <CardHeader>
                <div className="col-md-12">
                  <div className="reloaddiv"  style={{ textAlign: "right" }}>
                  <button className="btn btn-primary" onClick={reloadpage}>
                  Reload page
                    </button>
                  </div>

                  </div>
              </CardHeader>
              <CardBody>
                <iframe
                  id="invoicingApp"
                  src=""
                  style={{ height: "100vh", width: "100%" }}
                ></iframe>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default Invoicedetails;
