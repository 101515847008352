// export const apiUrl = 'http://127.0.0.1:8001/api';
// export const domainUrl = 'http://127.0.0.1:8001/';
// export const ameobapath = 'http://127.0.0.1:8000/';
// export const ameobaapipath = 'http://127.0.0.1:8000/api';
// export const invoicing_url = 'http://127.0.0.1:8002/';


export const apiUrl = 'https://app.e2eaccounting.com/api';
export const domainUrl = 'https://app.e2eaccounting.com/';
export const ameobapath = 'https://e2eamoeba.corientbiz.com/';
export const ameobaapipath = 'https://e2eamoeba.corientbiz.com/api';
export const invoicing_url = 'https://invoicing.e2eaccounting.com/';

