import {
  Card,
  CardBody,
  Col,
  
} from "reactstrap";
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
const axios = require("axios");
import { useLocation } from "react-router-dom";
import { apiUrl } from "../../constant";
import {userService} from '../../services/authentication';

const Login = ({ props }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const location = useLocation();
  const URL_PARAMS = new URLSearchParams(location.search);
  const ticketId = URL_PARAMS.get('ticketId');
  const autologin_token = URL_PARAMS.get('token');
  useEffect(() => {
   
    if (ticketId == null || autologin_token == null) {
      setErrorMessage('Ticket ID and/or token not found.');
      return;
    }
    axios({
      method: "get",
      url: `${apiUrl}/auto-login?ticketId=${ticketId}&token=${autologin_token}`,
    })
      .then((response) => {
          const reloadurl = "view_ticket/"+ticketId;
          userService.profile(response.data.access_token,true,reloadurl);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("authtoken", response.data.access_token);
      })
      .catch((err) => {
        setErrorMessage(err);
      });
      
  }, []);

  const renderErrorStatus = () => {
    if (errorMessage === null) {
      return '';
    }
    return (
      <div className="admin-login-page__error" type="error">
        {errorMessage}
      </div>
    );
  };


  return (
    <Col lg="5" md="7">
    <Card className="bg-secondary shadow border-0">
      <ToastContainer />
      <CardBody className="px-lg-5 py-lg-5" >
        <div className="text-center text-muted mb-4">
          <h1>E2E Accounting</h1>
        </div>
          <div className="admin-login-page__login-form">
            <button>
              {errorMessage === null
                ? 'Fetching ticket, please wait...'
                : 'Fetching ticket aborted !!'}
            </button>
          </div>
          {renderErrorStatus()}
      </CardBody>
      </Card>
    </Col>
  );
};

export default Login; 
