import React, { useEffect, useState} from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  InputGroup,
  FormControl,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
// import { Tem } from "../../services/emaillogsservice";
import { TemplatenotificationService } from "../../services/templatenotificationService";
import { Getyearmonth } from "../../services/getyearmonth";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./template.css";
import Popupmodel from "./Popupmodel";
import Editor from "react-simple-wysiwyg";
import Select from 'react-select';

const initialValues = {
  Email_Subject: "Reports Are Ready To Review",
  Description: null,
  // Email_From: null,
  // Email_To: null,
  // company_name: null,
};

function Report_review_notification() {
  const [userlist_Email_To, setUserlist_Email_To] = useState([]);
  const [userlist_Email_From, setUserlist_Email_From] = useState([]);
  const [comapnylist, setComapnylist] = useState([]);
  const [modal, setModal] = useState(false);
  const [confirmtitle, setConfirmtitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [inputFields, setInputFields] = useState(initialValues);
  const [test, setTest] = useState(false);
  const [company_name, setCompany_name] = useState(null);
  const [Email_To, setEmail_To] = useState(null);
  const [Email_From, setEmail_From] = useState(null);
  //   const {editorState,setEditorState}= useState();

  //const [years,setYears]=useState(["Select Year","2022","2023","2024","2025"]);

  function get_users_to() {
    TemplatenotificationService.getdata("getusers").then((response) => {
      if (response.status == 200) {
        const result = response.data.data.userlist;
        let new_object1 = "";
        new_object1 = result.map((data, index) => ({
          label: data.email,
          value: data.email,
          key: data.id,
        }));
        setUserlist_Email_To(new_object1);
      }
    });
  }
  function get_users_from() {
    TemplatenotificationService.getdata("getusers").then((response) => {
      if (response.status == 200) {
        const result2 = response.data.data.userlist;
        let new_object2 = "";
        new_object2 = result2.map((data, index) => ({
          label: data.email,
          value: data.email,
          key: data.id,
        }));
        setUserlist_Email_From(new_object2);
      }
    });
  }
  function get_company() {
    TemplatenotificationService.getdata("getcompany").then((response) => {
      if (response.status == 200) {
        const result = response.data.data.comapnylist;
        let new_object = [];
        new_object= result.map((data, index) => ({
          label: data.company_name,
          value: data.company_name,
          key: data.id,
        }));
        setComapnylist(new_object);
      }
    });
  }

  useEffect(() => {
    get_users_to();
    get_users_from();
    get_company();
  }, [status]);

  const inputhandle = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // if (typeof e.target !== "undefined") {
    //   setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    // } else {
    //   setInputFields({ ...inputFields, [e.name]: e.data });
    // }
  };

  const toggle = () => {
    let body =
      "Do you want to send email to this company " +
      company_name.value +
      " to " +
      Email_To.value +
      " from " +
      Email_From.value;

    setConfirmtitle(body);
    setModal(!modal);
  };

  const FormSubmit = (e) => {
    e.preventDefault();
    // console.log(inputFields.Description);return;
    if (
      company_name != null &&
      Email_From!= null &&
      Email_To != null &&
      inputFields.Description!=null
    ) {
      toggle();
    } else {
      toast.error("All field is required!!");
    }
  };

  const sendmail = (e) => {
    toggle();
    setLoading(true);
    e.preventDefault();
    TemplatenotificationService.sendmaildata(
      "Reportsreadytoreview",
      inputFields.Email_Subject,
      inputFields.Description,
      Email_From.value,
      Email_To.value,
      company_name.value
    )
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .then((data) => {
        setStatus(true);
        setLoading(false);
        if (data.status == "success") {
          toast.success(data.data.msg);
        } else {
          toast.error(data.data.msg);
        }
        // setComapnylist([]);
        //testing();
        // setUserlist_Email_To([]);
        // setUserlist_Email_From([]);
        
        setTest(true);
        setInputFields({Description: null});
        setCompany_name(null);
        setEmail_From(null);
        setEmail_To(null);
        console.log(inputFields);
        setStatus(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };


  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader>
                <div className="justify-content-end row">
                  <Col xl="12" className="align-items-center">
                    <h2 className="header-title">Report Review Notification</h2>
                  </Col>
                </div>
              </CardHeader>
              <CardBody>
             
                <form onSubmit={(e) => FormSubmit(e)}>
                  <div className="row">
                    <Col xl="4" className="align-items-center">
                      <FormGroup>
                        <Label for="exampleSelect">Company Name</Label>
                          <Select
                            value={company_name}
                            defaultValue={company_name}
                            onChange={setCompany_name}
                            options={comapnylist}
                          />

                      </FormGroup>
                    </Col>

                    <Col xl="4" className="align-items-center">
                      <FormGroup>
                        <Label for="exampleSelect">Email To</Label>
                        <Select
                            name="Email_To"
                            value={Email_To}
                            defaultValue={Email_To}
                            onChange={setEmail_To}
                            //onChange={ (e) => inputhandle(e)}
                            options={userlist_Email_To}
                          />
                      </FormGroup>
                    </Col>

                    <Col xl="4" className="align-items-center">
                      <FormGroup>
                        <Label for="exampleSelect">Email From</Label>
                        <Select
                            name="Email_From"
                            value={Email_From}
                            defaultValue={Email_From}
                            onChange={setEmail_From}
                            // onChange={ (e) => inputhandle(e)}
                            options={userlist_Email_From}
                          />
                      </FormGroup>
                    </Col>

                    {/* <Col xl="3" className="align-items-center">
                      <FormGroup>
                        <Label for="exampleSelect">Email Subject</Label>
                        <Input
                          id="exampleSelect"
                          name="Email_Subject"
                          type="text"
                          value={inputFields.Email_Subject}
                          onChange={(e) => inputhandle(e)}
                          disabled
                        ></Input>
                      </FormGroup>
                    </Col> */}

                    <Col xl="12" className="align-items-center">
                      <FormGroup>
                        <Label for="exampleSelect">Content</Label>
                        <Editor
                          name="Description"
                          containerProps={{
                            style: { resize: "vertical", minHeight: "250px" },
                          }}
                          value={inputFields.Description}
                          onChange={(e) => inputhandle(e)}
                        />
                      </FormGroup>
                    </Col>

                    <Col xl="12" className="align-items-center text-center">
                      {loading ? (
                        <Button color="primary" disabled>
                          <Spinner size="sm">Loading...</Spinner>
                          <span> Submit</span>
                        </Button>
                      ) : (
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      )}
                    </Col>
                  </div>
                </form>

                <CardFooter className="py-4"></CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* start popup model*/}
        <Popupmodel
          modal={modal}
          data={confirmtitle}
          toggle={toggle}
          functionname={sendmail}
        ></Popupmodel>
      </Container>
    </>
  );
}

export default Report_review_notification;
