const tableCustomStyles = {
    headCells: {
      style: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "21px",
        color: "#666666",
        border: "1px solid #EDEDED"
      },
    },
    cells:{
        style: {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "21px",
            color: "#898989",
            border: "1px solid #EDEDED"
          },
    }
  }
  export { tableCustomStyles };