import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router-dom";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState,useEffect } from "react";
import {staffmanageService} from '../../services/staffmanageService';
import 'assets/css/custom.css';
import { toast } from 'react-toastify';
import {Formloader} from 'components/Loader/PageLoader.js';
const axios = require("axios");

const Staffedit = () => {
  let { userid } = useParams();
  const [inputFields, setInputfields] = useState({});
  useEffect(() => {
    getuser();
  }, []);

  function getuser() {
    staffmanageService.getsingleuser(userid).then((response) => {
      setInputfields(response.data.data);
      
    });
  }

  const [formloader,setformloader] = useState(false);

  function inputhandler(e){
    setInputfields({...inputFields,[e.target.name]:e.target.value});
  } 
  
  function profileupdate(e) {
    e.preventDefault();
    setformloader(true);
    console.log(inputFields)
    staffmanageService.updateuser(inputFields).then((response)=>{
      // console.log(response);
       if(response.status == 200){
          setformloader(false); 
          toast.success(response.data.message);
          
       }else{
        setformloader(false); 
        toast.error(response.data.message);
       }
    });
  }
  return (
    <>
    
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
      
        <Row className="justify-content-md-center">
          
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
             
              <CardBody>
             
                <Form onSubmit={profileupdate}>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row className="justify-content-md-center">
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-fname"
                          >
                             Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={inputFields.name}
                            id="input-name"
                            placeholder=" Name"
                            name="name"
                            type="text"
                            readOnly
                            
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={inputFields.email}
                            placeholder="Email"
                            type="email"
                            name="email"
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            VAT Register
                          </label>
                            <select className="form-control" name="is_vat_registered"  value={inputFields.is_vat_registered} onChange={inputhandler}>
                              <option key="vatr_1" value="">Is VAT registered?</option>
                              <option key="vatr_2" value="Yes">Yes</option>
                              <option key="vatr_3" value="No">No</option>
                            </select>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >VAT Filling Frequency </label>
                          <select className="form-control" name="corient_vat_filing_frequency" onChange={inputhandler} value={inputFields.corient_vat_filing_frequency} >
                                <option value="" selected="selected"></option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Annual">Annually</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Financial Year Ends In
                          </label>
                          <select name="financial_year_end" id="financial_year_end" value={inputFields.financial_year_end} class="form-control " onChange={inputhandler}>
                            <option value="" selected="selected"></option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                          </FormGroup>
                      </Col>
                          <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            VAT Filling Quarter
                          </label>
                          <select className="form-control" name="corient_vat_filing_quarter" onChange={inputhandler} value={inputFields.corient_vat_filing_quarter} >
                          <option value="" selected="selected"></option>
                                      <option value="[3,6,9,12]">Quarter Ending March,June,September,December</option>
                                    <option value="[4,7,10,1]">Quarter Ending April,July,October,January</option>
                                    <option value="[5,8,11,2]">Quarter Ending May,August,November,February</option>
                                  </select>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name">
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input-first-name"
                            placeholder="Must be at least 10 characters long"
                            onChange={inputhandler}
                            type="password"
                            name="password"
                            value={inputFields.password}
                            
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Confirm Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input-last-name"
                            placeholder="Confirm new password"
                            type="password"
                            name="confirm_password"
                            onChange={inputhandler}
                            value={inputFields.confirm_password}
                            
                          />
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Leave blank password field if you do not want to change password.
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="text-center">
                        <button type="submit" className="btn btn-success mt-4" onClick={profileupdate} disabled={formloader}>
                          Update  {formloader ? (<Formloader height={20} color='#ffffff'></Formloader>) : (<></>)} 
                        </button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Staffedit;
