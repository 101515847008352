import { apiUrl, domainUrl,invoicing_url } from "../constant";
import { authtoken } from "../global.js";
const axios = require("axios");
export const userService = {
  login,
  logout,
  register,
  check_auth,
  profile,
  updateprofile,
  forgetpwd,
  refreshData,
  updatebankdetails,
  invoicebankdetail,
};

function login(email, password) {
  var bodyFormData = new FormData();
  bodyFormData.append("email", email);
  bodyFormData.append("password", password);
  return axios({
    method: "post",
    url: `${apiUrl}/login`,
    data: bodyFormData,
  })
    .then(
      function (response) {
        if (response.status == 200) {
          profile(response.data.access_token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("authtoken", response.data.access_token);
        }
        return response;
      },
      (error) => {
        return error.response;
      }
    )
    .catch(function (error) {
      return error;
    });
}

function forgetpwd(email) {
  var bodyFormData = new FormData();
  bodyFormData.append("email", email);
  return axios({
    method: "post",
    url: `${apiUrl}/forget-password`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(
      function (response) {
        return response;
      },
      (error) => {
        return error.response;
        console.log(error.response.status);
      }
    )
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function logout() {
  var bodyFormData = new FormData();
  const token = localStorage.getItem("authtoken");
  bodyFormData.append("token", token);
  return axios({
    method: "post",
    url: `${apiUrl}/logout`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      if (response.status == 200) {
        invoicelogout();
      }
      
      // return response;
    })
    .catch(function (response) {
      invoicelogout();
      //   console.log(response);
    });
}
function invoicelogout(){
  const user_data = JSON.parse(localStorage.getItem('user-info'));
  const email = user_data.email;
  const token='8227b2c8a23c575a63258878ddc62806';
    var bodyFormData = new FormData();
    bodyFormData.append("email", email);
    return axios({
      method: "get",
      url: `${invoicing_url}api/invoicelogout/`+email,
      // headers: { token: token },
    })
      .then(function (response) {
        console.warn("invoice logout");
        if (response.status == 200) {
          localStorage.clear();
          window.location.href = "/";
        }else{
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch(function (response) {
        localStorage.clear();
        window.location.href = "/";
          console.log(response);
      });
  
}

function register(name, email, password, confirmpassword, roles, phase_id) {
  var bodyFormData = new FormData();
  bodyFormData.append("name", name);
  bodyFormData.append("email", email);
  bodyFormData.append("password", password);
  bodyFormData.append("confirm-password", confirmpassword);
  bodyFormData.append("roles", roles);
  bodyFormData.append("phase_id", phase_id);
  return axios({
    method: "post",
    url: `${apiUrl}/register`,
    data: bodyFormData,
  })
    .then(function (response) {
      if (response.status == 200) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
      }
      return response;
    })
    .catch(function (response) {
      return response;
    });
}

function check_auth() {
  var bodyFormData = new FormData();
  bodyFormData.append("token", "tokencheck");
  return axios({
    method: "post",
    url: `${apiUrl}/token`,
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${authtoken}`,
    },
  })
    .then(
      function (response) {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          localStorage.clear();
        }
        return error.response.status;
      }
    )
    .catch(function (error) {
      localStorage.clear();
      window.location.href = "/auth";
    });
}

function profile(authtoken, reload = "",reloadurl = null) {
  return axios({
    method: "get",
    url: `${apiUrl}/profile`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log(response);
      if (response.status == 200) {
        const user_info = response.data;
        if (user_info.level == 3) {
          user_info.rolename = "superadmin";
          user_info.roletype = "lead";
        }
        if (user_info.level == 2) {
          user_info.rolename = "user";
          user_info.roletype = "lead";
        }
        if (user_info.level == 1) {
          user_info.rolename = "client";
          user_info.roletype = "lead";
        }
        if (user_info.level == 4) {
          user_info.rolename = "vendor";
          user_info.roletype = "lead";
        }

        localStorage.setItem("user-info", JSON.stringify(user_info));
        if (reload == "") {
          window.location.href = "/admin";
        }else if(reloadurl != null){
          window.location.href = "/admin/"+reloadurl;
        }
      }
    })
    .catch(function (response) {
      console.log(response);
      // return response;
    });
}

function updateprofile(
  user_id,
  corient_vat_filing_quarter,
  password,
  confirm_password
) {
  let token = localStorage.getItem("authtoken");
  var bodyFormData = new FormData();
  bodyFormData.append("id", user_id);
  bodyFormData.append("corient_vat_filing_quarter", corient_vat_filing_quarter);
  // console.log(password);
  if (password != "" || confirm_password != "") {
    bodyFormData.append("password", password);
    bodyFormData.append("confirm-password", confirm_password);
  }
  return axios({
    method: "post",
    url: `${apiUrl}/staff/update`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      if (response.status == 200) {
        profile(token, 1);
        localStorage.setItem("user", JSON.stringify(response.data.user));
      }
      return response;
    })
    .catch(function (response) {
      return response;
    });
}

function updatebankdetails(
  bank_name,
  bank_account_name,
  bank_account_no,
  bank_sort_code,
  company_registration_no,
  company_vat_no,
  email,
  address,
  country,
  state,
  city,
  phone,
  invoice_email,
  zip_code,
) {
  let token = localStorage.getItem("authtoken");
  var bodyFormData = new FormData();
  bodyFormData.append("bank_name", bank_name);
  bodyFormData.append("bank_account_name", bank_account_name);
  bodyFormData.append("bank_account_no", bank_account_no);
  bodyFormData.append("bank_sort_code", bank_sort_code);
  bodyFormData.append("company_registration_no", company_registration_no);
  bodyFormData.append("company_vat_no", company_vat_no);
  bodyFormData.append("email", email);
  bodyFormData.append("address", address);
  bodyFormData.append("country", country);
  bodyFormData.append("state", state);
  bodyFormData.append("city", city);
  bodyFormData.append("phone", phone);
  bodyFormData.append("invoice_email", invoice_email);
  bodyFormData.append("zip_code", zip_code);
  return axios({
    method: "post",
     url:`${invoicing_url}api/invoicebankdetail`,
    // url: `https://invoicing.e2eaccounting.com/api/invoicebankdetail`,
    data: bodyFormData,
    // headers: {Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      if (response.status == 200) {
        return response;
      }
    })
    .catch(function (response) {
      return response;
    });
}
function invoicebankdetail() {
  //  return  console.log(localStorage.getItem('user'));
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const email=user_info.email;
  return axios({
    method: "get",
    url:`${invoicing_url}api/getinvoicebankdetail/`+email,
    // url: `https://invoicing.e2eaccounting.com/api/getinvoicebankdetail/${email}`,
  })
    .then(function (response) {
      console.log(response);
      if (response.status == 200) {
        return response;
      }
    })
    .catch(function (response) {
      console.log(response);
      // return response;
    });
}

function refreshData() {
  let token = localStorage.getItem("authtoken");

  return axios({
    method: "post",
    url: `${apiUrl}/refreshData`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      return response;
    });
}
