import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Header from "components/Headers/Header.js";
import { authtoken } from "../global.js";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";

import { apiUrl } from "../constant";
import { useEffect, useState } from "react";
import axios from "axios";
const stripePromise = loadStripe(
  "pk_live_51IdGqRCslIXp9gtzb5rcStf52SO8RzwXoWt2GDhgdaQVLKVps3nMDOdO8o0kQCb5b2J2h51YBnEOFfRtcvYb8Nsc00YUsDIcKO"
);
const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardlist, setCardlist] = useState([]);
  const [status, setStatus] = useState(false);
  const [msg, setMsg] = useState(false);

  const token = localStorage.getItem("authtoken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // useEffect(() => {
  //   getcard();
  // }, []);

  useEffect(() => {
    // setLoading(true);
    // fetch(`${apiUrl}/getstripecard`, config)
    //   .then((response) => response.json())
    //   .then((json) =>
    //   setCardlist(json))
    //   console.warn(json)
    //   .finally((res) => {
    //     console.warn(res);
    //     setLoading(false);

    //   });
    // const response = axios.get(`${apiUrl}/getstripecard`, config);
    // console.warn(response);
    // if (response.data.status == "success") {
    //   let res=response.data.data;
    //   setCardlist(res);
    //   console.warn(res);
    // }

    getcard();
  }, [status]);

  const getcard = async () => {
    const response = await axios.get(`${apiUrl}/getstripecard`, config);
    console.warn(response.data);
    if (response.data.status == "success") {
      let res = response.data.data;
      setCardlist(res);
      console.warn(res);
    } else {
      setCardlist([]);
    }
  };
  // const getcard2 = async () => {
  //   const response = await axios.get(`${apiUrl}/getupdateAllPaymentMethods`, config);
  //   console.warn(response.data);
  //   if (response.data.status == "success") {
  //     let res = response.data.data;
  //     console.warn(res);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMsg(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.log("Error:", error);
    } else {
      console.log("Payment Method:", paymentMethod);
      try {
        const response = await fetch(`${apiUrl}/addPaymentMethod`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authtoken}`,
          },
          body: JSON.stringify({ paymentMethod }),
        });
        console.warn(response);
        if (response.status == 200 || response.status == "success") {
          // Payment method added successfully
          setStatus(true);
          setMsg(false);
          console.log("Payment method added");
          toast.success("Card add successfully..");
        } else {
          // Handle error response
          setMsg(false);
          console.log("Failed to add payment method");
          toast.error(response.data.message);
        }
      } catch (error) {
        // Handle fetch error
        setMsg(false);
        console.log("Error:", error);
        toast.error(error);
      }
    }
  };

  const formStyles = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  };

  const inputStyles = {
    marginBottom: "10px",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  };

  const buttonStyles = {
    backgroundColor: "#4285f4",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };

  return (
    <>
      <Header />
      <div className="header  pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col className="mb-12 mb-xl-0" xl="12">
                <form onSubmit={handleSubmit} style={formStyles}>
                  <label>Add Additional Card</label>
                  <br></br>
                  <CardElement options={{ style: inputStyles }} />
                  <br></br>
                  <br></br>
                  {msg ? (
                    <button class="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Please Wait ....
                    </button>
                  ) : (
                    <button type="submit" style={buttonStyles}>
                      Pay
                    </button>
                  )}
                </form>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {cardlist.card}
      {cardlist.data && cardlist.data?.length != 0 ? (
        <div className="container">
          <h5>Card List</h5>
          <table className="table table-striped table-bordered table-hover table-sm">
            <thead>
              <tr>
                <th>Card Type</th>
                <th>Last 4 Card Number</th>
                <th>Exp. Month</th>
                <th>Exp. Year</th>
              </tr>
            </thead>
            <tbody>
              {cardlist.data.map((item, i) => (
                <tr>
                  <td>{item.card.funding}</td>
                  <td>**** **** {item.card.last4}</td>
                  <td>{item.card.exp_month}</td>
                  <td>{item.card.exp_year}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const payment = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default payment;
