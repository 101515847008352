export const Getyearmonth = {
    getyear,
    getmonth,
};

function getyear(){
    var yearsname=["Select Year"];
        // const currentYear = new Date().getFullYear(); // 2020
        const d = new Date();
        let year = d.getFullYear();
        const previousYear =  year-1;
        for(var i=0; i<4; i++){
            yearsname.push(previousYear + i);
        }
    return yearsname;   
}
function getmonth(){
    let month=[ "Select Month",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"];
    return month;
}


