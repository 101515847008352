import Profile from "views/auth/Profile.js";
import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Forgotpassword from "views/auth/Forgotpassword";
import Resetpassword from "views/auth/Resetpassword";
import Dashboard from "views/user/Dashboard.js";
import Uploads from "views/uploads/Index";
import AutoLogin from "views/auth/Autologin.js";
import Activity from "views/Activity.js";
import Tickets from "views/user/Ticket.js";
import Payment from "views/payment.js";
import Staffs from "views/user/Staff.js";
import  Invoicedetails from "views/invoice/Invoicedetails.js";
import Reports from "views/reports/Reports";
import Emaillogs from "views/emaillogs/emaillogs";
import ReportNotification from "views/template_notification/ReportNotification";
import VatNotification from "views/template_notification/VatNotification";
import Yearendnotification from "views/template_notification/Yearendnotification";
import Report_review_notification from "views/template_notification/Report_review_notification";
import Manageclient from "views/template_notification/Manageclient";
import { ticketmanageService } from "services/ticketmanageService";
var user_info = {rolename:'default'};
if(user_info != null){
    if(JSON.parse(localStorage.getItem('user-info')) != null){
        user_info = JSON.parse(localStorage.getItem('user-info'));  
        console.warn(user_info);

      var unreadmessage="";
      ticketmanageService.getunreadticket().then((response) => {
        if(response.data.data){
          localStorage.setItem("unreadticket",response.data.data.message_count)
        }else{
          localStorage.setItem("unreadticket",0)
        }
      });

    }else{
        user_info.rolename = 'default';
    }

}


  switch (user_info.rolename) {
    case 'superadmin':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        // {
        //   path: "/last-activity",
        //   name: "Last Activity",
        //   icon: "ni ni-ui-04 text-th",
        //   component: Activity,
        //   layout: "/admin",
        // },
        {
          path: "/tickets",
          name: "Inbox",
          icon: "fas fa-inbox",
          component: Tickets,
          other:unreadmessage,
          layout: "/admin",
        },
        {
          path: "/staff",
          name: "Staffs",
          icon: "ni ni-email-83 text-th",
          component: Staffs,
          layout: "/admin",
        },
        // {
        //   path: "/user-profile",
        //   name: "User Profile",
        //   icon: "ni ni-settings-gear-65",
        //   component: Profile,
        //   layout: "/admin",
        // },
        {
          path: "/emaillogs",
          name: "Email Logs",
          icon: "far fa-envelope-open",
          component: Emaillogs,
          layout: "/admin",
        },
        {
          path: "/report_notification",
          name: "Report Notification",
          icon: "fas fa-envelope-open-text",
          component: ReportNotification,
          layout: "/admin",
        },
        {
          path: "/vat_notification",
          name: "Vat Notification",
          icon: "fas fa-file-alt",
          component: VatNotification,
          layout: "/admin",
        },
        {
          path: "/yearend_notification",
          name: "Year End Notification",
          icon: "fas fa-file",
          component: Yearendnotification,
          layout: "/admin",
        },
        {
          path: "/reportreview_notification",
          name: "Report Review Notification",
          icon: "fas fa-reply",
          component: Report_review_notification,
          layout: "/admin",
        },
        {
          path: "/Manageclient",
          name: "Manage Client",
          icon: "far fa-sticky-note",
          component: Manageclient,
          layout: "/admin",
        },

        
      ];
      break;
    case 'user':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        {
          path: "/tickets",
          name: "Inbox",
          icon: "fas fa-inbox",
          component: Tickets,
          other:unreadmessage,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        },
      
      ];
      break;
    case 'client':
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        {
          path: "/Uploads",
          name: "My Files",
          icon: "ni ni-box-2 text-th",
          component: Uploads,
          layout: "/admin",
        },
        {
          path: "/tickets",
          name: "Inbox",
          icon: "fas fa-inbox",
          component: Tickets,
          other:unreadmessage,
          layout: "/admin",
        },

        // {
        //   path: "/user-profile",
        //   name: "User Profile",
        //   icon: "ni ni-settings-gear-65",
        //   component: Profile,
        //   layout: "/admin",
        // },
        {
          path: "/payment",
          name: "Payment",
          icon: "ni ni-email-83 text-th",
          component: Payment,
          layout: "/admin",
        },
        {
          path: "/report",
          name: "Reports",
          icon: "fas fa-file",
          component: Reports,
          layout: "/admin",
        },
        {
          path: "/Invoicedetails",
          name: "Invoices",
          icon: "fas fa-file-alt",
          component: Invoicedetails,
          layout: "/admin",
        },




      ];
      break;
    case 'vendor':
        var routes = [
          {
            path: "/index",
            name: "Dashboard",
            icon: "ni ni-ui-04 text-th",
            component: Dashboard,
            layout: "/admin",
          },
          // {
          //   path: "/last-activity",
          //   name: "Last Activity",
          //   icon: "ni ni-ui-04 text-th",
          //   component: Activity,
          //   layout: "/admin",
          // },
          {
            path: "/tickets",
            name: "Inbox",
            icon: "fas fa-inbox",
            component: Tickets,
            other:unreadmessage,
            layout: "/admin",
          },
          {
            path: "/staff",
            name: "Staffs",
            icon: "ni ni-email-83 text-th",
            component: Staffs,
            layout: "/admin",
          },
          // {
          //   path: "/user-profile",
          //   name: "User Profile",
          //   icon: "ni ni-settings-gear-65",
          //   component: Profile,
          //   layout: "/admin",
          // },
          {
            path: "/emaillogs",
            name: "Email Logs",
            icon: "far fa-envelope-open",
            component: Emaillogs,
            layout: "/admin",
          },
          {
            path: "/report_notification",
            name: "Report Notification",
            icon: "fas fa-envelope-open-text",
            component: ReportNotification,
            layout: "/admin",
          },
          {
            path: "/vat_notification",
            name: "Vat Notification",
            icon: "fas fa-file-alt",
            component: VatNotification,
            layout: "/admin",
          },
          {
            path: "/yearend_notification",
            name: "Year End Notification",
            icon: "fas fa-file",
            component: Yearendnotification,
            layout: "/admin",
          },
          {
            path: "/reportreview_notification",
            name: "Report Review Notification",
            icon: "fas fa-reply",
            component: Report_review_notification,
            layout: "/admin",
          },
          {
            path: "/Manageclient",
            name: "Manage Client",
            icon: "far fa-sticky-note",
            component: Manageclient,
            layout: "/admin",
          },
  
          
        ];
        break;
    default:
      var routes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        {
          path: "/login/:companyname?",
          name: "Login",
          icon: "ni ni-key-25 text-th",
          component: Login,
          layout: "/auth",
        },
        {
          path: "/autologin",
          name: "Auto Login",
          icon: "ni ni-settings-gear-65",
          component: AutoLogin,
          layout: "/auth",
        },
        {
          path: "/register",
          name: "Register",
          icon: "ni ni-circle-08 text-th",
          component: Register,
          layout: "/auth",
        },
        {
          path: "/resetpassword",
          name: "Resetpassword",
          icon: "ni ni-circle-08 text-th",
          component: Resetpassword,
          layout: "/auth",
        },
        {
          path: "/forgotpassword",
          name: "Forgotpassword",
          icon: "ni ni-circle-08 text-th",
          component: Forgotpassword,
          layout: "/auth",
        },

      ];
  
  } 
 
export default routes;
