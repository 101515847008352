import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const Reportgraphicservice = {
    reportdata
};


function reportdata(startDate,endDate,tenet_id,periods,number,paymenttype,url_to_hit)
{
  var bodyFormData = new FormData();
  const token = localStorage.getItem("authtoken");
  if(url_to_hit==""){
    url_to_hit="executivereport.php";
  }
  bodyFormData.append("tenet_id", tenet_id);
  bodyFormData.append("startdate", startDate);
  bodyFormData.append("enddate", endDate);
  bodyFormData.append("periods", periods);
  bodyFormData.append("number", number);
  bodyFormData.append("paymenttype", paymenttype);
  bodyFormData.append("url_to_hit", url_to_hit)

  // if (executiveReport_dynamic) {
  //   var reportname = "executiveReport";
  //   bodyFormData.append("url_to_hit", url_to_hit);
  // } else if (profitLossReport_dynamic) {
  //   var reportname = "ProfitLossReport";
  //   bodyFormData.append("url_to_hit", url_to_hit);
  // }else if (balanceReport_dynamic) {
  //   var reportname = "balancereport";
  //   bodyFormData.append("url_to_hit", url_to_hit);
  // }
  // else if (generalLedgerReport_dynamic) {
  //   var reportname = "generalLedgerReport";
  //   bodyFormData.append("url_to_hit", url_to_hit);
  // }
  return axios({
    method: "post",
    url: `${apiUrl}/xero/getReportsPDFFromXero`,
    data: bodyFormData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then(function (response) {
    if(response.status == 200){
      //let data={"id": response.data.chartd[0],"id2": response.data.chartd[1],"id3": response.data.chartd[2]};
         return response;  
    }
 }, error => {
   return error.response; 
 })
 .catch(function (error) {
   // console.log(error.response.status);
 }); 

 

 
};

