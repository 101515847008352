import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import ReactDatetime from "react-datetime";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Spinner,
} from "reactstrap";
import ReactQuill from "react-quill";
const axios = require("axios");
import { authtoken } from "global.js";
import { apiUrl } from "../../constant";
import { toast } from "react-toastify";
import Select from "react-select";
// core components
import { ticketmanageService } from "../../services/ticketmanageService.js";
import { TemplatenotificationService } from "services/templatenotificationService";

const Ticket = (props) => {
  const history = useHistory();
  const [tickets, setInboxTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [allquery, setallquery] = useState(0);
  const [createticketModalOpen, setcreateTicketModalOpen] = useState(false);
  const [value, setValue] = useState("");
  const [comapnylist, setComapnylist] = useState([]);
  const [company_name, setCompany_name] = useState();
  const [company_list, setCompany_list] = useState([]);
  const [status, setStatus] = useState(false);

  const get_inbox_data = async (pageNumber = 1, type = 0, all = null) => {
    setallquery(all);
    let data = "";
    if (company_name == null) {
      data = "";
    } else {
      data = company_name;
    }
    ticketmanageService
      .gettickets(pageNumber, type, all, data)
      .then((response) => {
        setInboxTickets(response.data.data.tickets);
        console.warn(response.data.data.tickets);
        setCurrentPage(response.data.data.page);
        setLastPageData(response.data.data.pages);
      });
  };

  const [tabstate, settabState] = useState({ tabs: 1 });
  const setAllQueryfunction = () => {
    var allshow = 0;
    if (allquery == 1) {
      setallquery(0);
    } else {
      allshow = 1;
      setallquery(1);
    }
    get_inbox_data(0, 0, allshow);
  };
  const toggleNavs = (e, tabstate, index) => {
    e.preventDefault();
    settabState({
      [tabstate]: index,
    });
    let type = index - 1;
    get_inbox_data(0, type, allquery);
  };

  const handlePageClick = (e, index) => {
    e.preventDefault();
    let type = tabstate.tabs - 1;
    get_inbox_data(index, type, allquery);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    let type = tabstate.tabs - 1;
    get_inbox_data(prev, type, allquery);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    let type = tabstate.tabs - 1;
    get_inbox_data(next, type, allquery);
  };
  const get_company_list = () => {
    ticketmanageService.getcompanylist().then((response) => {
      setCompany_list(response.data.data.comapnylist);
    });
  };

  useEffect(() => {
    get_inbox_data(0);
  }, [company_name]);

  useEffect(() => {
    get_company_list();
    if (user_info.level == 3) {
      // get_company();
    }
  }, []);

  function ticket_details(ticketnumber) {
    history.push({
      pathname: "view_ticket/" + ticketnumber,
    });
  }

  const initialStateData = {
    file: "",
    title: "",
  };

  const [inputFields, setInputfields] = useState(initialStateData);
  function onRespondClick() {
    if (inputFields.title != "" && value != "") {
      const formData = new FormData();
      formData.append(
        "file",
        new Blob([inputFields.file], { type: inputFields.file.type }),
        inputFields.file.name || "file_1"
      );
      formData.append("title", inputFields.title);
      formData.append("content", value);
      setStatus(true);
      axios({
        method: "post",
        url: `${apiUrl}/ticket/create`,
        data: formData,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(
          function (response) {
            console.log(response);
            setStatus(false);
            if (response.status == 200) {
              setInputfields(initialStateData);
              setValue("");
              setcreateTicketModalOpen(false);
              toast.success(response.data.message);
              get_inbox_data(0);
            }
          },
          (error) => {
            return error.response;
          }
        )
        .catch(function (error) {});
    } else {
      toast.error("title and content required !!!");
    }
  }
  function onFileChange(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.files[0] });
  }
  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const searchcompany = (e) => {
    if (e.target.value != "") {
      if (e.key === "Enter") {
        e.preventDefault();
        var company_name = e.target.value;
        //  get_staff_data(1,company_name)
      }
    }
  };
  const companyChange = (event) => {
    if (event.target.value) {
      setCompany_name(event.target.value);
    }
  };
  const create_para ={
    color:"#000",
    fontWeight: "bold",
  }
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader>
                <div class="row">
                  {user_info.level == 3  || user_info.level == 4 ? (
                    <div className="col-md-6">
                      <select
                        class="form-control"
                        name="company_id"
                        id="company"
                        onChange={companyChange}
                        value={company_name}
                      >
                        <option>Please select company</option>
                        {company_list.map((comp) => (
                          <option value={comp.user_id}>
                            {comp.company_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                  <div className="text-right col-md-6">
                    {user_info.level == 3 ? (
                      <> <button
                        className="btn btn-primary btn-sm"
                        onClick={(e) => setAllQueryfunction()}
                      >
                        {" "}
                        {allquery == 1 ? "My Queries" : "All Queries"}
                      </button>
                      <button
                      className="btn btn-info btn-sm"
                      onClick={() => setcreateTicketModalOpen(true)}
                    >
                      {" "}
                      Create Ticket
                    </button> </>
                    ) : null}

                  </div>
                </div>
                <div className="col-md-6">
                  <Nav
                    className="nav-fill flex-column flex-md-row"
                    id="tabs-icons-text"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        aria-selected={tabstate.tabs === 1}
                        className={classnames("mb-sm-1 mb-md-0", {
                          active: tabstate.tabs === 1,
                        })}
                        onClick={(e) => toggleNavs(e, "tabs", 1)}
                        href="#pablo"
                        role="tab"
                      >
                        Open Queries
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-selected={tabstate.tabs === 2}
                        className={classnames("mb-sm-1 mb-md-0", {
                          active: tabstate.tabs === 2,
                        })}
                        onClick={(e) => toggleNavs(e, "tabs", 2)}
                        href="#pablo"
                        role="tab"
                      >
                        Close Queries
                      </NavLink>
                    </NavItem>

                    { user_info.level == 1 ? ( 

                    <NavItem>
                    <NavLink>
                      <p className="mb-sm-1 mb-md-0 create_para" style={create_para} onClick={() => setcreateTicketModalOpen(true)}>
                      Create Ticket
                      </p>
                       {/* <button
                       className="btn btn-info btn-sm"
                       onClick={() => setcreateTicketModalOpen(true)}
                     >
                      {" "}
                      Create Ticket
                    </button>  */}
                    </NavLink>
                  </NavItem>
                    ) :null
}
                    {user_info.level == 3 ? (
                      <NavItem>
                        <NavLink
                          aria-selected={tabstate.tabs === 3}
                          className={classnames("mb-sm-1 mb-md-0", {
                            active: tabstate.tabs === 3,
                          })}
                          onClick={(e) => toggleNavs(e, "tabs", 3)}
                          href="#pablo"
                          role="tab"
                        >
                          Unread Queries
                        </NavLink>
                      </NavItem>
                    ) : null}
                  </Nav>
                </div>
              </CardHeader>
              <CardBody>
                <TabContent activeTab={"tabs" + tabstate.tabs}>
                  <TabPane tabId={"tabs" + tabstate.tabs}>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Number</th>
                          <th scope="col">Title</th>
                          <th scope="col">Latest response from</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tickets.length > 0 && tickets ? (
                          tickets.map((trans, i) => (
                            <tr
                              onClick={(e) => {
                                ticket_details(trans.ticket_number);
                              }}
                              key={i}
                              style={
                                trans.unread == 1 && user_info.level == 1
                                  ? { color: "#20B8c5" }
                                  : trans.unread_staff == 1 &&
                                    user_info.level != 1
                                  ? { color: "#20B8c5" }
                                  : null
                              }
                            >
                              <th scope="row">#{trans.ticket_number}</th>
                              <td>{trans.title}</td>
                              <td>{trans.author_name}</td>
                              <td>{trans.date}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>
                </TabContent>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem disabled={currentPage <= 1}>
                        <PaginationLink
                          onClick={handlePreviousClick}
                          previous
                        />
                      </PaginationItem>
                      {[...Array(last_page)].map((page, i) => (
                        <PaginationItem
                          active={i + 1 === currentPage}
                          key={i + 1}
                        >
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, i + 1)}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage >= last_page}>
                        <PaginationLink onClick={handleNextClick} next />
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="taskdetailmodal"
        isOpen={createticketModalOpen}
        scrollable={true}
      >
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
            Create Ticket
          </h5>
          <button
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => setcreateTicketModalOpen(!createticketModalOpen)}
          >
            <span aria-hidden={true}>×</span>
            <div></div>
          </button>
        </div>
        <ModalBody>
          <FormGroup>
            <Input
              placeholder="Title"
              type="text"
              name="title"
              value={inputFields.title}
              onChange={(e) => inputhandler(e)}
            />
          </FormGroup>
          <FormGroup>
            <ReactQuill
              theme="snow"
              name="content"
              value={value}
              onChange={setValue}
            />
          </FormGroup>
          <FormGroup>
            <input
              type="file"
              className="form-control"
              name="file"
              onChange={onFileChange}
            />
          </FormGroup>
          <FormGroup>
            {status ? (
              <Button
                disabled
                color="success"
                size="extra-small">
                <Spinner size="sm"></Spinner>
                <span>{" Create Query "}</span>
              </Button>
            ) : (
              <Button
                color="success"
                size="extra-small"
                onClick={onRespondClick.bind(this)}
              >
                Create Query
              </Button>
            )}
          </FormGroup>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Ticket;
