import './loader.css';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  PopoverBody,
  UncontrolledPopover
} from "reactstrap";
import { TailSpin } from  'react-loader-spinner'
const PageLoader = (props) => {
    return (
        <>
        <div className="outer-layer">
          <div  className="inner-layer">
            <span><TailSpin color='skyblue' height={props.height}/></span>
          </div>
        </div>
        </>
    );
}

export const Formloader = (props) => {
   return(
    <span style={{display:'inline-block'}}><TailSpin color='#ffffff' height={props.height}/></span>
   );
}

export const Modalformloader = (props) => {


  return(
    <>
      <Modal className='modalformloaderstyle'
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // toggle={() => setPhaseSelectionModalOpen(!PhaseSelectionModalOpen)}
            isOpen={props.visible}
            style={{backgroundColor:'#fff0'}}
          >
            <ModalBody style={{backgroundColor:'#fff0'}}>
              <div style={{textAlign:'center'}}>
               <span style={{display:'inline-block'}}>
                 <TailSpin style={{display:'inline-block'}} display='inline-block' color='#2fc3b9' height={props.height}/>
               </span>
              </div>  
            </ModalBody>
          </Modal>
    </>
   //<span style={{display:'inline-block'}}><TailSpin color={props.color} height={props.height}/></span>
  );
}

export default PageLoader;