import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
  } from "reactstrap";
  import React, { useState } from "react";
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useHistory } from "react-router-dom";
  import {apiUrl} from '../../constant';
  import {userService} from '../../services/authentication';
  // import './credentialstyle.css';
  const axios = require("axios");
  
  const Forgotpassword = () => {
    const history = useHistory();
  
    const [inputFieldEmail, setInputemail] = useState({
      email: "",
    });
  
    const [inputFieldpass, setInputpassword] = useState({
      password: "",
    });
  
    function authenticate(e) {
      e.preventDefault();
      var email = inputFieldEmail.email;
      userService.forgetpwd(email).then((response)=>{
         if(response.status == 200){
          toast.success(response.data.message);
         }else{
          toast.error(response.data.message);
         }
      });
    }
  
    const inputsHandleremail = (e) => {
      setInputemail({ [e.target.name]: e.target.value });
    };
  
    const inputsHandlerpass = (e) => {
      setInputpassword({ [e.target.name]: e.target.value });
    };
  
  
    // function redirect(path){
    //   //  path.preventDefault();
    //    history.push(path);    
    // }
  
    return (
      <>
        <Col lg="5" md="7">
        <ToastContainer />
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h1>Forgot password</h1>
              </div>
              <Form role="form" onSubmit={authenticate}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      name="email"
                      autoComplete="new-email"
                      onChange={inputsHandleremail}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    <span>Submit</span>
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <p className="text-light" onClick={(e) => history.push('login')}>
                <a
                  className="text-light"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Singnin</small>
                </a>
              </p>
            </Col>
          </Row>
        </Col>
      </>
    );
  };
  
  export default Forgotpassword;
  