import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  InputGroup,
  FormControl,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
function Popupmodel(props) {
  return (
<>
<Modal isOpen={props.modal}  className="modal-confirm">
          <ModalHeader >
            <div className="icon-box">
            <i className="ni ni-check-bold"></i>
            </div>
            <h4 className="modal-title">Are you sure?</h4>
          </ModalHeader>
          <ModalBody>{props.data}</ModalBody>
          <ModalFooter>
            <Button color="success" onClick={(e)=>props.functionname(e)}>
             Ok
            </Button>{" "}
            <Button color="danger" onClick={props.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
</>
  )
}

export default Popupmodel
