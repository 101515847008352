import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";
import "./style.css";
import { apiUrl, domainUrl } from "../../constant";
import Moment from "moment";
import Uploadsfilelist from "./uploadsfilelist";
import { Progress } from "reactstrap";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Button,
  Spinner,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";

const RecentUploads = (props) => {
  const token = localStorage.getItem("authtoken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [recentUploadsdata, setRecentUploadsdata] = useState([]);
  const [companyname, setCompanyname] = useState("");
  const [autoMail, setautoMail] = useState("");
  const fetchrecentuploads = () => {
    return axios.get(`${apiUrl}/getrecentUploadedFiles`, config).then((response) => {
      setRecentUploadsdata(response.data.data),
        setCompanyname(response.data.company_name),
        setautoMail(response.data.auto_mail);
    });
  };

  const copytexttoclipboard = () => {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    toast.success("Copied");
  };

  useEffect(() => {
    fetchrecentuploads();
  }, [props.data]);

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            {/* <Col md="4">
              <CardTitle>Recent Uploads</CardTitle>
            </Col> */}
            <Col md="12">
              <CardTitle>
                <b>Company : {companyname}</b>
              </CardTitle>
            </Col>
          </Row>
          <CardText className="rightcard-below-card">
            <p style={{ fontSize: "12px" }}>
              Send files to this email address :{" "}
              <input id="myInput" type="text" value={autoMail} />
              <button
                class="btn btn-primary"
                style={{ padding: "2px 10px" }}
                onClick={copytexttoclipboard}
              >
                Copy
              </button>
            </p>
            <CardTitle>Recent Uploads </CardTitle>
            {recentUploadsdata &&
              recentUploadsdata.length > 0 &&
              recentUploadsdata.map((userObj, index) =>
                index < 3 ? (
                  <Card
                    className="card-stats mb-4 mb-lg-0 recentupdate"
                    key={userObj.id}
                  >
                    <CardBody>
                      <Row>
                        <Col className="col-auto">
                          <div
                            color="secondary"
                            style={{
                              background: "#fff",
                              top: "50%",
                              left: "50%",
                            }}
                            className="icon   shadow"
                          >
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "14px",
                                padding: "40% 0",
                                textAlign: "center",
                              }}
                            >
                              {userObj.file_type}
                            </p>
                          </div>
                        </Col>
                        <div className="col">
                          <CardTitle className="mb-0 filename">
                            {/* {userObj.file_name.substring(0, 25)} */}
                            {userObj.file_name}
                          </CardTitle>
                          <span className="h2  mb-0 date">
                            Uploaded{" "}
                            {Moment(userObj.updated_at).format(
                              "DD-MM-YYYY h:mm:ss a"
                            )}{" "}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                ) : null
              )}

            {/* <Card className="card-stats mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                    <Col className="col-auto">
                        <div color="secondary" style={{background: "#fff", top: "50%",
                                left: "50%"}} className="icon   shadow">
                          
                        <p style={{fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "14px",padding: "40% 0",
                            textAlign: "center"}}>Invoice</p> 
                        </div>
                      </Col>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Total traffic
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">350,897</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>

                <Card className="card-stats mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                    <Col className="col-auto">
                        <div color="secondary" style={{background: "#fff", top: "50%",
                                left: "50%"}} className="icon   shadow">
                          
                        <p style={{fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "14px",padding: "40% 0",
                            textAlign: "center"}}>Invoice</p> 
                        </div>
                      </Col>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Total traffic
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">350,897</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card> */}
          </CardText>
        </CardBody>
      </Card>
    </>
  );
};

// const  Uploadsfile=(prop)=>{

//   const fileTypes = ["JPG", "PNG", "GIF"];
//   const [file, setFile] = useState(null);
//   const handleChange = (file) => {
//     setFile(file);
//     console.log(file);
//   };
//   const filetypecheck =(file) =>{
//   toast.error(file);
//   console.log(file);
//   }
//   const filesizecheck= (file) =>{
//   console.log(file);
//   toast.error(file);
//   }
//   return ( <>
//                 <FormGroup>
//               <FileUploader data={file} label="Drag & drop files" onSizeError={filesizecheck} maxSize={25} className='form-control-alternative' multiple={true}
//                   handleChange={handleChange} onTypeError={filetypecheck}
//                   name="file"  types={fileTypes} children={<>
//                   <div className='fileuploadwapper'>
//                     <p className='fileicons'>
//                       <i class="far fa-file-alt first"></i>
//                       <i class="far fa-file-alt second"></i>
//                       <i class="far fa-file-alt third "></i>
//                    </p>
//                    <div className='filetext'><p className='para1' style={{fontSize: "24px",color: "#666666",fontWeight: "500"}}>Drag & drop <span style={{fontSize: "24px",color: "#7A69F0",fontWeight: "500"}}>files</span></p>
//                    <p className='para2' style={{fontSize: "15px",fontWeight: "400",lineHeight: "18px",color:"#666666"}}>or <span style={{color:"#7A69F0",borderBottom:"1px solid"}}>Browse files</span> on your computer</p>

//                    </div>

//                    </div></>}/>
//                    <p className='notice'><span>Max file size 25 MB</span></p>
//               </FormGroup>

//   </>)
// }

function Index() {
  //system/getsettings
  const [selectedOption, setSelectedOption] = useState();
  const [submitstatus, setSubmitstatus] = useState(false);
  const [fileTypes, setFileTypes] = useState([]);
  const [progressbar, setProgressbar] = useState(true);
  const [uploadstatus, setUploadstatus] = useState(true);
  const [submitdata, setSubmitdata] = useState(0);

  //const fileTypes = ["JPG", "PNG", "GIF"];
  const [file, setFile] = useState([]);
  const [searchbox, setSearchbox] = useState();
  const [otherinput, setOtherinput] = useState(false);
  const [otherinputvalue, setOtherinputvalue] = useState("");
  const [options, setOptions] = useState([
    { value: "", label: "Please select file type" },
    { value: "invoice", label: "Invoice" },
    { value: "billing", label: "Billing" },
    { value: "sample invoice", label: "Sample Invoice" },
    { value: "other file", label: "Other File" },
  ]);
  // const options = [
  //   { value: "", label: "Please select file type" },
  //   { value: "invoice", label: "Invoice" },
  //   { value: "billing", label: "Billing" },
  //   { value: "sample invoice", label: "Sample Invoice" },
  //   { value: "other file", label: "Other File" },
  // ];
  const handleChange = (file) => {
    // setFile(file);
    const imagesArray = [];

    for (let i = 0; i < file.length; i++) {
      imagesArray.push(file[i]);
    }
    setFile(imagesArray);
  };
  const filetypecheck = (file) => {
    toast.error(file);
  };
  const filesizecheck = (file) => {
    toast.error(file);
  };
  const token = localStorage.getItem("authtoken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchData = () => {
    return axios
      .get(`${apiUrl}/system/getsettings`, config)
      .then((response) =>
        setFileTypes(response.data.message.allowed_file_extensions.split(","))
      );
  };

  useEffect(() => {
    fetchData();
  }, [selectedOption]);

  useEffect(() => {
    optionchange();
  }, [selectedOption]);

  const formsubmit = (e) => {
    e.preventDefault();
    setSubmitstatus(true);

    if (selectedOption == null || file.length == 0) {
      setSubmitstatus(false);
      toast.error("Every fields is required!!!");
    } else {
      submitformdata()
        .then(
          function (response) {
            setSubmitstatus(false);
            if (response.status == 200) {
              setUploadstatus(false);
              setTimeout(() => {
                setUploadstatus(true);
              }, 1000);
              setSubmitdata(submitdata + 1);
              setFile([]);
              setSelectedOption("");
              toast.success(
                selectedOption.value + "  file has been uploaded successfully"
              );
            } else {
              setUploadstatus(false);
              toast.error("file type errors ");
            }
          },
          (error) => {
            setUploadstatus(false);
            console(error);
            toast.error(error);
          }
        )
        .catch(function (error) {
          setUploadstatus(false);
          toast.error(error);
        });

      // return axios({
      //   method: "post",
      //   url: `${apiUrl}/uploadfile`,
      //   data: bodyFormData,
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     "Content-Type": "multipart/form-data",
      //   },
      // })
      // .then(
      //   function (response) {
      //     setSubmitstatus(false);
      //     if (response.status == 200) {

      //       setUploadstatus(false);
      //       setTimeout(() => {
      //           setUploadstatus(true);
      //       }, 1000);
      //       setSubmitdata(submitdata + 1);
      //       setFile([]);
      //       setSelectedOption('');
      //       toast.success(
      //         selectedOption.value + "  file has been uploaded successfully"
      //       );
      //     } else {
      //       setUploadstatus(false);
      //       toast.error("file type errors ");
      //     }
      //   },
      //   (error) => {
      //     setUploadstatus(false);
      //     console(error);
      //     toast.error(error);
      //   }
      // )
      // .catch(function (error) {
      //   setUploadstatus(false);
      //   toast.error(error);
      // });
    }
  };

  const submitformdata = async () => {
    var bodyFormData = new FormData();
    let i = 0;
    let urllist = [];
    console.warn(file);
    file.forEach((image_file) => {
      bodyFormData.append('images[]', image_file);
  });
    // for (i; i < file.length; i++) {
      const token = localStorage.getItem("authtoken");
      bodyFormData.append("filetype", selectedOption.value);
      bodyFormData.append("other_file_name", otherinputvalue);
      //bodyFormData.append("images[]", file);
      const response = await axios({
        method: "post",
        url: `${apiUrl}/uploadfile`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      const json = await response;
      urllist = json;
    // }
    return urllist;
  };

  const handlesearchbox = () => {
    setSearchbox(1);
  };

  const optionchange = () => {
    console.warn(selectedOption);
    if (typeof selectedOption !== "undefined") {
      let type = selectedOption.value;
      if (type == "other file") {
        setOtherinput(true);
      } else {
        setOtherinput(false);
      }
    }
  };

  const otherfilehandle = (e) => {
    setOtherinputvalue(e.target.value);
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
        <div className="custom-heading">
          <Row>
            <Col xs={12}>
              <h2> My Files</h2>
            </Col>
            {/* <Col xs={10}>
              <Form className="navbar-search  form-inline mr-3 d-none d-md-flex ml-lg-auto">
                <FormGroup className="mb-0">
                  <InputGroup className="input-group-alternative" id="search">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search" type="text" />
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col> */}
          </Row>
        </div>
        <body-Container>
          <Row>
            <Col xs={12} xl={7}>
              <Card className="cardstyle">
                <CardBody>
                  <CardTitle>Please Select File Type</CardTitle>
                  <CardText>
                    <Form>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <Select
                              className="form-control-alternative"
                              value={selectedOption}
                              defaultValue={selectedOption}
                              // onChange={(e) => {
                              //   optionchange(e);
                              // }}
                              onChange={setSelectedOption}
                              options={options}
                            />
                          </FormGroup>
                        </Col>
                        {otherinput ? (
                          <Col md="12">
                            <FormGroup>
                              <Input
                                id="otherfileinput"
                                placeholder="Enter other file name"
                                type="text"
                                onChange={(e) => otherfilehandle(e)}
                                value={otherinputvalue}
                              />
                            </FormGroup>
                          </Col>
                        ) : null}
                        <Col md="12">
                          {/* <Uploadsfile file={file}   onChange={getdata}></Uploadsfile> */}
                          <FormGroup>
                            <FileUploader
                              disabled={uploadstatus ? false : true}
                              multiple="true"
                              label="Drag & drop files"
                              onSizeError={filesizecheck}
                              maxSize={25}
                              className="form-control-alternative"
                              handleChange={handleChange}
                              onTypeError={filetypecheck}
                              name="file"
                              types={fileTypes}
                              children={
                                <>
                                  <div className="fileuploadwapper">
                                    {uploadstatus ? (
                                      <p className="fileicons">
                                        <i class="far fa-file-alt first"></i>
                                        <i class="far fa-file-alt second"></i>
                                        <i class="far fa-file-alt third "></i>
                                      </p>
                                    ) : (
                                      <p className="fileicons2">
                                        <i class="far fa-check-circle"></i>
                                      </p>
                                    )}

                                    {uploadstatus ? (
                                      <div className="filetext">
                                        <p
                                          className="para1"
                                          style={{
                                            fontSize: "24px",
                                            color: "#666666",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Drag & drop{" "}
                                          <span
                                            style={{
                                              fontSize: "24px",
                                              color: "#7A69F0",
                                              fontWeight: "500",
                                            }}
                                          >
                                            files
                                          </span>
                                        </p>
                                        {file.length > 0 ? (
                                          <p className="selected_file">
                                            Selected ({file.length}) File
                                          </p>
                                        ) : null}
                                        <p
                                          className="para2"
                                          style={{
                                            fontSize: "15px",
                                            fontWeight: "400",
                                            lineHeight: "18px",
                                            color: "#666666",
                                          }}
                                        >
                                          or{" "}
                                          <span
                                            style={{
                                              color: "#7A69F0",
                                              borderBottom: "1px solid",
                                            }}
                                          >
                                            Browse files
                                          </span>{" "}
                                          on your computer
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="filetext">
                                        <p
                                          className="para1"
                                          style={{
                                            fontSize: "24px",
                                            color: "#666666",
                                            fontWeight: "500",
                                          }}
                                        >
                                          File successfully{" "}
                                          <span
                                            style={{
                                              fontSize: "24px",
                                              color: "#7A69F0",
                                              fontWeight: "500",
                                            }}
                                          >
                                            uploaded!
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </>
                              }
                            />

                            <p className="notice">
                              <span>Max file size 25 MB</span>
                            </p>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <CardTitle style={{ marginBottom: "0px" }}>
                            {submitstatus ? (
                              <Button
                                color="primary"
                                disabled
                                style={{
                                  fontSize: "20px",
                                  padding: "10px 25px",
                                  margin: "10px 0",
                                }}
                              >
                                <Spinner size="sm">Loading...</Spinner>
                                <span> Loading</span>
                              </Button>
                            ) : (
                              <Button
                                style={{
                                  fontSize: "20px",
                                  padding: "10px 25px",
                                  margin: "10px 0",
                                }}
                                color="primary"
                                onClick={formsubmit}
                              >
                                Upload
                              </Button>
                            )}
                          </CardTitle>
                        </Col>
                      </Row>
                    </Form>
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col xs={12} xl={5}>
              <div className="right-card">
                <RecentUploads data={submitdata}></RecentUploads>
              </div>
            </Col>
          </Row>
        </body-Container>

        <div className="custom-below">
          <Row>
            <Col md="12">
              <div className="filters">
                <h2>Uploaded Files</h2>
                {/* <Button
                  className="btn-icon btn-3"
                  color="primary"
                  type="button"
                  onClick={handlesearchbox}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-filter"></i>
                  </span>
                  <span className="btn-inner--text">Filter</span>
                </Button> */}
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md="12">
            <Card className="card-stats mb-4 mb-lg-0">
              <CardBody>
                <Row>
                  <Uploadsfilelist
                    data={submitdata}
                    box={searchbox}
                  ></Uploadsfilelist>
                  {/* <div className="col">
                  <CardTitle className="text-uppercase text-muted mb-0">
                    Total traffic
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">350,897</span>
                </div>
                <Col className="col-auto">
                  <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                    <i className="fas fa-chart-bar" />
                  </div>
                </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Index;
