import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
// core components
import { ticketmanageService } from "../../services/ticketmanageService.js";
import TicketViewer from '../../components/ticket-viewer';

const TicketDetails = (props) => {
  const params  = useParams();
  const [ticket, setTicket] = useState({});
 const get_ticket_data = async () => {
    ticketmanageService.getticket(params.ticketnumber).then((response) => {
      setTicket(response.data.data);
      // and call seen api
      ticketmanageService.ticketseen(params.ticketnumber).then((response) => {

      })
    });
  }

  useEffect(() => {
    get_ticket_data()
  }, []);
  
  function getTicketViewProps() {
    return {
        ticket: ticket,
        onChange: get_ticket_data.bind(this),
    };
}
  
  return (
    <div className="admin-panel-view-ticket__ticket-view">
    <TicketViewer {...getTicketViewProps()} />
    </div>
  );

};

export default TicketDetails;
