//admins
import Dashboard from "views/user/Dashboard.js";
import Uploads from "views/uploads/Index";
import Uploadsfilelist from "views/uploads/uploadsfilelist";

import Activity from "views/Activity.js";
import Tickets from "views/user/Ticket.js";
import TicketDetails from "views/user/TicketDetails.js";
import Staffs from "views/user/Staff.js";
import Staffedit from "views/user/Staffedit.js";
import Profile from "views/auth/Profile.js";
import  Invoicedetails from "views/invoice/Invoicedetails.js";
import Reports from "views/reports/Reports";
import Emaillogs from "views/emaillogs/emaillogs";
import ReportNotification from "views/template_notification/ReportNotification";
import VatNotification from "views/template_notification/VatNotification";
import Yearendnotification from "views/template_notification/Yearendnotification";
import Report_review_notification from "views/template_notification/Report_review_notification";
import Payment from "views/payment.js";
import Manageclient from "views/template_notification/Manageclient";
import { ticketmanageService } from "services/ticketmanageService";
var user_info = {rolename:'default'};
if(user_info != null){
    if(JSON.parse(localStorage.getItem('user-info')) != null){
        user_info = JSON.parse(localStorage.getItem('user-info'));  
        
        var unreadmessage="";
        ticketmanageService.getunreadticket().then((response) => {
          console.log(response);
        if(response.data.data){
          localStorage.setItem("unreadticket",response.data.data.message_count)
        }else{
          localStorage.setItem("unreadticket",0)
        }
            // return unreadmessage=response.data.data.message_count;
        });
    }else{
        user_info.rolename = 'default';
    }

}


 // non visible routes



  switch (user_info.rolename) {
    case 'superadmin':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        // {
        //   path: "/last-activity",
        //   name: "Last Activity",
        //   icon: "ni ni-tv-2 text-th",
        //   component: Activity,
        //   layout: "/admin",
        // }
        // {
        //   path: "/last-activity",
        //   name: "Last Activity",
        //   icon: "ni ni-tv-2 text-th",
        //   component: Activity,
        //   layout: "/admin",
        // },
        {
          path: "/tickets",
          name: "Inbox",
          other:unreadmessage,
          icon: "fas fa-inbox",
          component: Tickets,
          layout: "/admin",
        }, 
        {
          path: "/staff",
          name: "Staffs",
          icon: "ni ni-email-83 text-th",
          component: Staffs,
          layout: "/admin",
        }, {
          path: "/edituser/:userid",
          name: "Edit Staff",
          icon: "ni ni-single-02 text-th",
          component: Staffedit,
          layout: "/admin",
        },
        {
          path: "/view_ticket/:ticketnumber",
          name: "Ticket Details",
          icon: "ni ni-single-02 text-th",
          component: TicketDetails,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        },
        {
          path: "/emaillogs",
          name: "Email Logs",
          icon: "far fa-envelope-open",
          component: Emaillogs,
          layout: "/admin",
        },
        {
          path: "/report_notification",
          name: "Report Notification",
          icon: "fas fa-envelope-open-text",
          component: ReportNotification,
          layout: "/admin",
        },
        {
          path: "/vat_notification",
          name: "Vat Notification",
          icon: "fas fa-file-alt",
          component: VatNotification,
          layout: "/admin",
        },
        {
          path: "/yearend_notification",
          name: "Year End Notification",
          icon: "fas fa-file",
          component: Yearendnotification,
          layout: "/admin",
        },
        {
          path: "/reportreview_notification",
          name: "Report Review Notification",
          icon: "fas fa-reply",
          component: Report_review_notification,
          layout: "/admin",
        },

        {
          path: "/Manageclient",
          name: "Manage Client",
          icon: "far fa-sticky-note",
          component: Manageclient,
          layout: "/admin",
        },


      ];
      break;
    case 'user':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        {
          path: "/tickets",
          name: "Inbox",
          icon: "fas fa-inbox",
          component: Tickets,
          other:unreadmessage,
          layout: "/admin",
        }, 
        {
          path: "/view_ticket/:ticketnumber",
          name: "Ticket Details",
          icon: "ni ni-single-02 text-th",
          component: TicketDetails,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        }
      ];
      break;
    case 'client':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        {
          path: "/Uploads",
          name: "Uploads",
          icon: "ni ni-tv-2 text-th",
          component: Uploads,
          layout: "/admin",
        },
        {
          path: "/tickets",
          name: "Inbox",
          icon: "fas fa-inbox",
          other:unreadmessage,
          component: Tickets,
          layout: "/admin",
        },
        {
          path: "/payment",
          name: "Payment",
          icon: "ni ni-email-83 text-th",
          component: Payment,
          layout: "/admin",
        },
        {
          path: "/view_ticket/:ticketnumber",
          name: "Ticket Details",
          icon: "ni ni-single-02 text-th",
          component: TicketDetails,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        },

        {
          path: "/report",
          name: "Reports",
          icon: "fas fa-file",
          component: Reports,
          layout: "/admin",
        },
        {
          path: "/Invoicedetails",
          name: "Invoices",
          icon: "fas fa-file-alt",
          component: Invoicedetails,
          layout: "/admin",
        },
        
      ];
      break;
      case 'vendor':
        var adminroutes = [
          {
            path: "/index",
            name: "Dashboard",
            icon: "ni ni-tv-2 text-th",
            component: Dashboard,
            layout: "/admin",
          },
          // {
          //   path: "/last-activity",
          //   name: "Last Activity",
          //   icon: "ni ni-tv-2 text-th",
          //   component: Activity,
          //   layout: "/admin",
          // }
          // {
          //   path: "/last-activity",
          //   name: "Last Activity",
          //   icon: "ni ni-tv-2 text-th",
          //   component: Activity,
          //   layout: "/admin",
          // },
          {
            path: "/tickets",
            name: "Inbox",
            other:unreadmessage,
            icon: "fas fa-inbox",
            component: Tickets,
            layout: "/admin",
          }, 
          {
            path: "/staff",
            name: "Staffs",
            icon: "ni ni-email-83 text-th",
            component: Staffs,
            layout: "/admin",
          }, {
            path: "/edituser/:userid",
            name: "Edit Staff",
            icon: "ni ni-single-02 text-th",
            component: Staffedit,
            layout: "/admin",
          },
          {
            path: "/view_ticket/:ticketnumber",
            name: "Ticket Details",
            icon: "ni ni-single-02 text-th",
            component: TicketDetails,
            layout: "/admin",
          },
          {
            path: "/user-profile",
            name: "User Profile",
            icon: "ni ni-settings-gear-65",
            component: Profile,
            layout: "/admin",
          },
          {
            path: "/emaillogs",
            name: "Email Logs",
            icon: "far fa-envelope-open",
            component: Emaillogs,
            layout: "/admin",
          },
          {
            path: "/report_notification",
            name: "Report Notification",
            icon: "fas fa-envelope-open-text",
            component: ReportNotification,
            layout: "/admin",
          },
          {
            path: "/vat_notification",
            name: "Vat Notification",
            icon: "fas fa-file-alt",
            component: VatNotification,
            layout: "/admin",
          },
          {
            path: "/yearend_notification",
            name: "Year End Notification",
            icon: "fas fa-file",
            component: Yearendnotification,
            layout: "/admin",
          },
          {
            path: "/reportreview_notification",
            name: "Report Review Notification",
            icon: "fas fa-reply",
            component: Report_review_notification,
            layout: "/admin",
          },
  
          {
            path: "/Manageclient",
            name: "Manage Client",
            icon: "far fa-sticky-note",
            component: Manageclient,
            layout: "/admin",
          },
  
  
        ];
        break;
      default:
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        }
      ];
  
  }

export default adminroutes;
