import { useState, useEffect } from "react";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import UserHeader from "components/Headers/UserHeader.js";
import { ticketmanageService } from "../services/ticketmanageService.js";
import { authtoken } from "global.js";
import { apiUrl } from "../constant.js";
const axios = require("axios");
const TicketViewer = (props) => {
  const history = useHistory();
  const [value, setValue] = useState("");
  const [RespondQuery, setRespondQuery] = useState(false);
  const [DeleteQuery, setDeleteQuery] = useState(false);
  const [CloseQuery, setCloseQuery] = useState(false);

  const ticket = props.ticket;
  console.warn(ticket);

  function getHeaderViewProps() {
    return {
      title: "View Query",
      description:
        "This query has been sent by a customer. Here you can respond or assign the query",
    };
  }
  function onReopenClick() {
    // ticket repoen api call
    ticketmanageService.ticketReOpen(ticket.ticketNumber).then((response) => {
      toast.success(response.data.message);
      props.onChange();
    });
  }
  function onCloseClick() {
    // ticket repoen api call
    setCloseQuery(true);
    ticketmanageService.ticketClose(ticket.ticketNumber).then((response) => {
      setCloseQuery(false);
      toast.success(response.data.message);
      props.onChange();
    });
  }
  function onDeleteClick() {
    // ticket repoen api call
    setDeleteQuery(true);
    ticketmanageService.ticketDelete(ticket.ticketNumber).then((response) => {
      setDeleteQuery(false);
      toast.success(response.data.message);
      history.push({
        pathname: "/tickets",
      });
    });
  }

  const initialStateData = {
    file: "",
  };


  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === 'remove') {
        return <></>;
      }
    },
  };
  const [inputFields, setInputfields] = useState(initialStateData);
  function onRespondClick() {
    if (value === "") {
      toast.error("please enter comments");
      return;
    }
    setRespondQuery(true);
    const formData = new FormData();
    formData.append(
      "file",
      new Blob([inputFields.file], { type: inputFields.file.type }),
      inputFields.file.name || "file_1"
    );
    formData.append("ticketnumber", ticket.ticketNumber);
    formData.append("comment", value);
    axios({
      method: "post",
      url: `${apiUrl}/ticket-respond`,
      data: formData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(
        function (response) {
          setRespondQuery(false);
          console.log(response);
          if (response.status == 200) {
            setInputfields(initialStateData);
            setValue("");
            toast.success(response.data.message);
            props.onChange();
          }
        },
        (error) => {
          return error.response;
        }
      )
      .catch(function (error) {});
  }
  function onFileChange(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.files[0] });
  }

  const downloadfile = (path, name, extension) => {
    ticketmanageService
      .download_file(path, name, extension)
      .then((response) => {});
  };
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  return (
    <>
      <UserHeader {...getHeaderViewProps()} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="12">
            <Card className="card-profile shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      #{ticket.ticketNumber} {ticket.title}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    Status : &nbsp;
                    {ticket.closed == true ? (
                      <Button
                        color="primary"
                        size="extra-small"
                        onClick={onReopenClick.bind(this)}
                      >
                        RE-OPEN
                      </Button>
                    ) : (
                      "Opened"
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <br></br>
              <CardBody className="pt-0 pt-md-4">
                <div
                  className="timeline timeline-one-side"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  <div className="timeline-block">
                    {/* {ticket.authorprofilePic != null ? (
                      <span className="timeline-step ">
                        <img
                          alt={ticket.authorname}
                          className="avatar avatar-xs mr-2"
                          src={ticket.authorprofilePic}
                        />
                      </span>
                    ) : (
                      <span className="timeline-step badge-info">
                        <i className="ni ni-single-02"></i>
                      </span>
                    )} */}
                  
                  <span className="timeline-step badge-info">
                        <i className="ni ni-single-02"></i>
                      </span>

                    <div className="timeline-content">
                      <div class="d-flex justify-content-between pt-1">
                        <div>
                          <span className="text-muted text-sm font-weight-bold">
                            {ticket.authorname}{" "}
                          </span>
                        </div>
                        <div className="text-right">
                          <small className="text-muted">
                            <i className="fas fa-clock mr-1"></i>
                            {ticket.date}
                          </small>
                        </div>
                      </div>
                      {/* <h5 class="mt-3 mb-0">New message</h5> */}
                      <p className="text-sm mt-1 mb-0 ticket_content" style={{whiteSpace: 'pre-wrap'}}>
                        {ticket.content}
                        {/* {parse(ticket.content, options)} */}
                        
                        </p>
                      {ticket.file != null ? (
                        <div className="d-flex justify-content-between pt-1">
                          <div>
                            <span className="text-muted text-sm font-weight-bold"></span>
                          </div>
                          <div
                            className="text-right"
                            onClick={() =>
                              downloadfile(
                                ticket.file,
                                ticket.filename,
                                ticket.extension
                              )
                            }
                          >
                            <small className="text-muted">
                              {ticket.file}
                              <i className="ni ni-cloud-download-95 mr-1"></i>
                            </small>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {ticket.events && ticket.events.length
                    ? ticket.events.map((item, i) => (
                        <React.Fragment key={i}>
                          {item.type == "COMMENT" ? (
                            <div className="timeline-block">
                              {/* {ticket.authorprofilePic != null ? (
                                <span className="timeline-step ">
                                  <img
                                    alt={item.author.name}
                                    className="avatar avatar-xs mr-2"
                                    src={item.author.profilePic}
                                  />
                                </span>
                              ) : (
                                <span className="timeline-step badge-info">
                                  <i className="ni ni-single-02"></i>
                                </span>
                              )} */}
                              {item.author.level == 1 ? (
                                <span className="timeline-step badge-primary">
                                  <i className="ni ni-single-02"></i>
                                </span>
                              ) : (
                                <span className="timeline-step badge-success">
                                  <i className="ni ni-single-02"></i>
                                </span>
                              )

                              }

                              <div className="timeline-content">
                                <div className="d-flex justify-content-between pt-1">
                                  <div>
                                    <span className="text-muted text-sm font-weight-bold">
                                      {item.author.name}{" "}
                                    </span>
                                  </div>
                                  <div className="text-right">
                                    <small className="text-muted">
                                      <i className="fas fa-clock mr-1"></i>
                                      {item.date}
                                    </small>
                                  </div>
                                </div>
                                <p className="text-sm mt-1 mb-0 ticket_content" style={{whiteSpace: 'pre-wrap'}}>
                                  {/* {item.content} */}
                                  {parse(item.content, options)}
                                </p>
                                {item.file != null ? (
                                  <div className="d-flex justify-content-between pt-1">
                                    <div>
                                      <span className="text-muted text-sm font-weight-bold"></span>
                                    </div>
                                    <div
                                      className="text-right"
                                      onClick={() =>
                                        downloadfile(
                                          item.file,
                                          item.filename,
                                          item.extension
                                        )
                                      }
                                    >
                                      <small className="text-muted" download>
                                        {item.file}
                                        <i class="ni ni-cloud-download-95 mr-1"></i>
                                      </small>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : item.type == "ASSIGN" ||
                            item.type == "UN_ASSIGN" ? (
                            <div className="timeline-block">
                              <span
                                className={
                                  item.type == "ASSIGN"
                                    ? "timeline-step badge-success"
                                    : "timeline-step badge-info"
                                }
                              >
                                <i className="ni ni-single-02"></i>
                              </span>
                              <div className="timeline-content">
                                <p className="text-sm mt-1 mb-0">
                                  <span className="text-muted text-sm font-weight-bold">
                                    {item.author.name}
                                  </span>{" "}
                                  {item.type == "ASSIGN"
                                    ? "assigned"
                                    : "un-assigned"}{" "}
                                  this ticket to {item.content} on {item.date}
                                </p>
                              </div>
                            </div>
                          ) : item.type == "RE_OPEN" || item.type == "CLOSE" ? (
                            <div className="timeline-block">
                              <span
                                className={
                                  item.type == "RE_OPEN"
                                    ? "timeline-step badge-info"
                                    : "timeline-step badge-danger"
                                }
                              >
                                <i className="ni ni-single-02"></i>
                              </span>
                              <div className="timeline-content">
                                <p className="text-sm mt-1 mb-0">
                                  <span className="text-muted text-sm font-weight-bold">
                                    {item.author.name}
                                  </span>{" "}
                                  {item.type == "RE_OPEN"
                                    ? "reopened"
                                    : "closed"}{" "}
                                  this ticket on {item.date}
                                </p>
                              </div>
                            </div>
                          ) : null}
                        </React.Fragment>
                      ))
                    : null}
                </div>
                <hr className="my-4" />
                {ticket.closed == false ? (
                  <FormGroup>
                    <ReactQuill
                      theme="snow"
                      name="comment"
                      value={value}
                      onChange={setValue}
                    />
                    <input type="file" name="file" onChange={onFileChange} />
                  </FormGroup>
                ) : null}
                <Row className="align-items-center">
                  <Col xs="8">
                    {ticket.closed == false && RespondQuery == false ? (
                      <Button
                        color="success"
                        size="extra-small"
                        onClick={onRespondClick.bind(this)}
                      >
                        Respond Query
                      </Button>
                    ) : RespondQuery ? (
                      <Button color="success" disabled>
                        <Spinner size="sm"></Spinner>
                        <span>{" Respond Query "}</span>
                      </Button>
                    ) : null}
                  </Col>

                  <Col className="text-right" xs="4">
                    {user_info.level == 3 && DeleteQuery == false ? (
                      <Button
                        color="danger"
                        size="extra-small"
                        onClick={onDeleteClick.bind(this)}
                      >
                        Delete Query
                      </Button>
                    ) : DeleteQuery ? (
                      <Button color="danger" disabled>
                        <Spinner size="sm"></Spinner>
                        <span>{" Delete Query "}</span>
                      </Button>
                    ) : null}
                    {ticket.closed == false && CloseQuery == false ? (
                      <Button
                        color="danger"
                        size="extra-small"
                        onClick={onCloseClick.bind(this)}
                      >
                        Close Query
                      </Button>
                    ) : CloseQuery ? (
                      <Button color="danger" disabled>
                        <Spinner size="sm"></Spinner>
                        <span>{" Close Query "}</span>
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TicketViewer;
