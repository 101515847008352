import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  
} from "reactstrap";
import React, { useState } from "react";
import { useHistory,useParams } from "react-router-dom";
import {userService} from '../../services/authentication';
import { ToastContainer, toast } from 'react-toastify';
const axios = require("axios");

const Login = () => {
  const history = useHistory();
  let { companyname } = useParams();
  if(typeof companyname === "undefined"){
    companyname="";
  }
 localStorage.setItem("vendername",companyname);

  const [inputFieldEmail, setInputemail] = useState({
    email: "",
  });

  const [inputFieldpass, setInputpassword] = useState({
    password: "",
  });

  const [error,setError]=useState(null);
  const errorDiv = error ? (
    <UncontrolledAlert className="alert-danger" fade={false}>
      {error}
    </UncontrolledAlert>
  ) : (
    ""
  );
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const authenticate = (e) => {
    e.preventDefault();
    var email = inputFieldEmail.email;
    var pass = inputFieldpass.password;
    if(email == '' || pass == ''){
      toast.error('All fields are Required !');
      return false;
    }
    var bodyFormData = new FormData();
    userService.login(inputFieldEmail.email,inputFieldpass.password).then((response)=>{
//       console.warn(response);
//       console.warn(response.status);
// console.warn(response.data.msg);return;
        if(response.status == 200){
          toast.success(response.data.message);
        }else{
          toast.error(response.data.msg);
        }
    });
    return;
  }

  const inputsHandleremail = (e) => {
    setInputemail({ [e.target.name]: e.target.value });
  };

  const inputsHandlerpass = (e) => {
    setInputpassword({ [e.target.name]: e.target.value });
  };


  // const handlePasswordChange =()=>{
  //     setPasswordInput(evnt.target.value);
  // }

  const togglePassword = () =>{
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <ToastContainer />
          <CardBody className="px-lg-5 py-lg-5" >
            <div className="text-center text-muted mb-4">
              <h1>{  localStorage.getItem("vendername") ? localStorage.getItem("vendername") : "E2E Accounting" }</h1>
            </div>
            {errorDiv}
            <Form id="login-form" role="form" onSubmit={(e) => authenticate(e)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="new-email"
                    onChange={inputsHandleremail}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText onClick={togglePassword}>
                    {passwordType =='password' ?
                        <i class="fa fa-eye" aria-hidden="true" ></i> :
                        <i class="fa fa-eye-slash" aria-hidden="true" ></i>
            }
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={passwordType}
                    name="password"
                    autoComplete="new-password"
                    onChange={inputsHandlerpass}
                    required
                  />
                </InputGroup>
                {/* <a
                className="text-light"
                href="/auth/register"
                onClick={(e) => history.push('forgotpassword')}
              >
                <small>Forgot password?</small>
              </a> */}
               {/* <>
               <div onClick={togglePassword}>
                {passwordType =='password' ?
                        <i class="fa fa-eye" aria-hidden="true" > Password hide </i> :
                        <i class="fa fa-eye-slash" aria-hidden="true" > Password show</i>
            }
                      </div>
               </>  */}
              </FormGroup>
              <div className="text-center">
                <Button className="my-4 col-12" color="primary" type="submit">
                  <span>Sign in</span>
                </Button>     
                <a
                className="text-light"
                href="/auth/register"
                onClick={(e) => history.push('register')}
              >
                {/* <small>Create new account</small> */}
              </a>  
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-6">
          <Col xs="6">
          
          </Col>
          <Col className="text-right" xs="6">
          
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
